import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import CardList from '../../../components/CardList/CardList';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const ProcedimentosMedicosList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    const columns = [
        // { name: 'id', title: '#' },        
        { name: 'titulo', title: 'titulo' , filterable: true, primary: true },
        { name: 'descricao', title: 'descricao', width: 250, filterable: true, secondary: true },                
        { name: 'codigo', title: 'codigo', width: 250, filterable: true, secondary: true },                
        { name: 'valor', title: 'valor', width: 250, filterable: true, secondary: true },                
    ]    


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
// titulo, 
// descricao, 
// codigo, 
// valor,                
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Procedimentos Médicos" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"procMedicos"} endpoint={"procMedicos"} columns={columns} pkid={'id'} />:
                            
                            <GridList modelName={"procMedicos"} 
                                editModelRoute={'/procMedicos'}
                                newModelRoute={'/procMedicos/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'procMedicos'}
                                enableActions={true}
                                pkid={'id'}
                                columns={[
                                { name: 'titulo', title: 'Titulo', width:200 },
                                { name: 'descricao', title: 'Descricao', width:350 },
                                { name: 'codigo', title: 'Codigo', width:350 },
                                { name: 'valor', title: 'Valor', width:350 },
                            ]}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

