import React from 'react';
import { Grid } from "@material-ui/core"
import { Field } from 'redux-form';
import { renderTextField, renderTextAreaField, renderSelectField, renderDatePicker } from '../../../components/Fields/Fields';
import EstadoOptions from './EstadoOptions';
import PaisOptions from './PaisOptions';
import MyDivider from '../../../components/MyDivider';

const JobSection = (props) => {
    const upper = value => value && value.toUpperCase()
    const idx = props.idx || 0;

    return (
        <>
            <Grid item xs={6}>
                <Field name={`jobs.${idx}.title`} type="text" component={renderTextField} label="Título" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>

            <Grid item xs={6}>
                <Field name={`jobs.${idx}.employer`} type="text" component={renderTextField} label="Empregador" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>

            <Grid item xs={3}>
                <Field name={`jobs.${idx}.startDate`} type="date" label="Data de início" component={renderDatePicker} style={{maxWidth: '100%', width: 300}} {...{ required: true }} />
            </Grid>

            <Grid item xs={3}>
                <Field name={`jobs.${idx}.endDate`} type="date" label="Data de conclusão" component={renderDatePicker} style={{maxWidth: '100%', width: 300}} />
            </Grid>

            <Grid item xs={6}>
                <Field name={`jobs.${idx}.country`} type="text" component={renderSelectField} label="País" normalize={upper} style={{width: 330}} {... {required: true}} >
                    <PaisOptions />
                </Field>
            </Grid>

            <Grid item xs={6}>
                <Field name={`jobs.${idx}.state`} type="text" component={renderSelectField} label="Estado" normalize={upper} style={{width: 330}} {... {required: true}} >
                    <EstadoOptions />
                </Field>
            </Grid>

            <Grid item xs={6}>
                <Field name={`jobs.${idx}.city`} type="text" component={renderTextField} label="Cidade" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>

            <Grid item xs={12}>
                <Field name={`jobs.${idx}.description`} type="text" component={renderTextAreaField} label="Descrição" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>
            
            <Grid item xs={12}>
                <MyDivider />
            </Grid>
        </>
    )
}

export default JobSection;