const AuthState = {
    isAuthenticated: JSON.parse(sessionStorage.getItem('auth')).isAuthenticated,
    grants: JSON.parse(sessionStorage.getItem('auth')).grants,

    logout(callback) {
        sessionStorage.removeItem('token');
        sessionStorage.setItem('auth',
            JSON.stringify({isAuthenticated: false, grants: []})
        );
        callback();
    },

    getAuthenticated() {
        const {isAuthenticated} = JSON.parse(sessionStorage.getItem('auth'));
        return isAuthenticated || false;
    },

    allowedTo(path) {
        const {grants} = JSON.parse(sessionStorage.getItem('auth')) || [];
        const {profile} = JSON.parse(sessionStorage.getItem('auth')) || {};

        return (profile.isAdmin === true) || (grants.indexOf(path) !== -1);
    },

    async getUserInfo() {
        const {userInfo} = await JSON.parse(sessionStorage.getItem('auth')) || {};

        return userInfo;
    },

    getProfileInfo() {
        const {profile} = JSON.parse(sessionStorage.getItem('auth')) || {};

        return profile;
    },

    getCompanyInfo() {
        const {company} = JSON.parse(sessionStorage.getItem('auth')) || {};

        return company;
    },

    getCompanyInfoDetail() {
        const {companyInfo} = JSON.parse(sessionStorage.getItem('auth')) || {};

        return companyInfo;
    },

      
    getToken() {
        return sessionStorage.getItem('token');
    },

    getCandidato() {
        const {candidatoId} = JSON.parse(sessionStorage.getItem('auth')) || {};

        return candidatoId;
    },

    updateUserPhoto(url) {
        const auth = JSON.parse(sessionStorage.getItem('auth')) || {};
        auth.userInfo.photo = url;

        sessionStorage.setItem('auth',
            JSON.stringify(auth)
        );
    }
}

export default AuthState;
