import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,  
  Divider,
  Grid,
} from '@material-ui/core';
import { useHistory } from 'react-router';

import RouteButton from '../../../../components/RouteButton';

import Swiper from 'react-id-swiper';
// import 'swiper/css/swiper.css';

// import 'swiper/css';

//import { getToken, deleteToken } from "../../../../services/firebaseNotification/index";

// import LOCAL_URL from '../../../../services/qrCodeConfig'

const useStyles = makeStyles(() => ({
  root: {}
}));

const Liderancas = props => {
  const { className, ...rest } = props;
  const profile = props.profile;
  const classes = useStyles();
  const history = useHistory();

  const handleAssessor = (e) => {        
        history.push(`/usersLiderancas/A`);
  }

  const handleVereador = (e) => {
    history.push(`/usersLiderancas/V`);  
  }
  
  const handleDepEstadual = (e) => {
    history.push(`/usersLiderancas/E`);    
  }

  const handleDepFederal = (e) => {
    history.push(`/usersLiderancas/F`); 
  }  
  
  const handleSenador = (e) => {
    history.push(`/usersLiderancas/S`); 
  }   
  
  const handlePrefeito = (e) => {
    history.push(`/usersLiderancas/P`); 
  }   
  
  const handleGovernador = (e) => {
    history.push(`/usersLiderancas/G`); 
  }   
  
  const handleOutros = (e) => {
    history.push(`/usersLiderancas/O`); 
  }   

  const handlePreCadastro = (e) => {
    history.push(`/usersLiderancas/PG`); 
  }   

  const handleTodos = (e) => {
    history.push(`/usersLiderancas/T`); 
  }       


  // const handleShare =  (e) => {

  //   try {
  //   AuthState.getUserInfo().then( async (luser) => {

  //     const shareData = {
  //       title: 'Fator Político',
  //       text: 'Faça seu cadastro no Fator Político',
  //       url: `${LOCAL_URL}/${luser.id}/regusuario/`
  //     } 
  //     await navigator.share(shareData);
      
  //   }) 
  //   } catch(err) {
  //     alert('Não suportado')

  //   }

  // }   

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title={"Lideranças "}
          // subheader="Exibir Cracha"
          
        />

        <Grid container spacing={3} wrap="wrap">

            <RouteButton 
              title={'Assessor'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#65b4ff'} 
              handlerAction={handleAssessor}
              />

            <RouteButton 
              title={'Vereador'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#70d4e6'} 
              handlerAction={handleVereador}
              /> 

            <RouteButton 
              title={'Dep. Estadual'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#f78585'} 
              handlerAction={handleDepEstadual}
              />  

            <RouteButton 
              title={'Dep. Federal'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#9a6af5'} 
              handlerAction={handleDepFederal}
              />

            <RouteButton 
              title={'Senador'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#9b499b'} 
              handlerAction={handleSenador}
              /> 

            <RouteButton 
              title={'Prefeito'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#237c57'} 
              handlerAction={handlePrefeito}
              /> 

            <RouteButton 
              title={'Governador'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#e4e27c'} 
              handlerAction={handleGovernador}
              />   

            <RouteButton 
              title={'Outros'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#b6b6b6'} 
              handlerAction={handleOutros}
              />  

            <RouteButton 
              title={'Pré-Cadastro'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#b6b6b6'} 
              handlerAction={handlePreCadastro}
              />     

            <RouteButton 
              title={'Todos'} 
              iconName={'colaboradores'} 
              iconSize={36} 
              bgColor={'#b6b6b6'} 
              handlerAction={handleTodos}
              />  

        </Grid>
          

        <Divider style={{marginTop:50}} />          
 

      </form>
    </Card>
  );
};

// Liderancas.propTypes = {
//   className: PropTypes.string
// };

export default Liderancas;
