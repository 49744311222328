import React, {useState, useEffect} from 'react';
import Carousel from '../../../../components/Carousel/Carousel';
import { api } from '../../../../services';
import { remoteDeleteImage, uploadFile } from '../../../../services/images';

const ImageCard = (props) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const {entity, imageContext, isSelfEditing, imageUrl, name, maxImages} = props;

    useEffect(() => {
        loadRemoteImages()
    }, [imageUrl]);

    const loadRemoteImages = () => {
        setLoading(true);
        api.get(imageUrl)
        .then(response => {
            setImages(response.data.images)
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            console.error(error);
        });
    }

    const doUploadFile = (entity, images, base64Image, fileImage) => {
        if (fileImage) {
            setLoading(true);
            uploadFile(entity, images, base64Image, fileImage, imageContext)
            .then(uploadedFiles => {
                loadRemoteImages()
            });
        }

    }

    const deleteImage = (image) => {
        if (entity) {
            if (!image.filename) {
                const tokens = image.context.split('/');
                image.filename = tokens[tokens.length - 1]
            }
            setLoading(true);
            remoteDeleteImage(entity, image, image.context.replace(`/${image.filename}`, ''))
            .then(deletedImage => {
                loadRemoteImages()
            });
        }
    }

    return (
        <>
            <div>{name}</div>
            <Carousel
                entity={entity}
                images={images}
                loading={loading}
                addCallback={doUploadFile}
                deleteCallback={deleteImage}
                maxImages={isSelfEditing ? maxImages : 0}
            />
        </>
    )
};

export default ImageCard;
