import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import AuthState from '../../../../auth/Auth';
import Icon from '../../../../components/icon/icons';
import { isMobile } from 'react-device-detect';

import background from "./topbar.png";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  signInButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();

  const [notifications] = useState([]);

  const signOut = () => {
    AuthState.logout(() => {
      history.push("/login")
    });
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}

      style = {{
          marginTop: 20, 
          marginLeft:50, 
          height:40 , 
          backgroundImage: `url(${background})`,           
          
        }
        }
    >
      <Toolbar style = {{marginTop: 0, marginLeft:'2%'}}>
        {/* <RouterLink to="/" >
          <img
            alt="Logo"
            src="/images/logos/FatorPolitico.png"
            width={isMobile?'110':'150' }
            height={isMobile?'60':'72' }
          />
        </RouterLink> */}
        <div className={classes.flexGrow} />

        <Link href='https://www.youtube.com' style = {{marginTop: -24, marginLeft: 20}}>
        <Icon 
          icon='youtube' 
          color= '#000'
          size= '24' 
           />
        </Link>

        <Link href='https://www.facebook.com' style = {{marginTop: -24, marginLeft: 20}}>
        <Icon 
          icon='facebook2' 
          color= '#000'
          size= '20' 
           />
        </Link>

        <Link href='https://www.instagram.com' style = {{marginTop: -24, marginLeft: 20}}>
        <Icon 
          icon='instagram' 
          color= '#000'
          size= '20' 
           />
        </Link>

        <Link href='https://twitter.com' style = {{marginTop: -24, marginLeft: 20}}>
        <Icon 
          icon='twitter' 
          color= '#000'
          size= '20' 
           />
        </Link>                


        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              
            </Badge>
            
          </IconButton>
          {AuthState.getAuthenticated() === true && 
          <IconButton
            className={classes.signOutButton}
            color='#000'
            onClick={signOut}
            style = {{marginTop: -24}}
          >
            <InputIcon />
          </IconButton>
          }
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon style = {{marginTop: -20}} />
          </IconButton>          
        </Hidden>

      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
