import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import { useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { api } from '../../../services';
import { ToastMessage } from '../../../layouts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const ResumeList = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const deleteCallback = (reponse) => {
        ToastsStore.success('Demanda excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    const downloadPDF = (filename, pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `${filename}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const pdfGenerate = (rowId) => {
        setLoading(true);
        api.post(`resumes/${rowId}/pdf`)
        .then(response => {
            console.log(response)

            const base64STR = response.data.content;

            downloadPDF(rowId, base64STR)
            
            ToastMessage.success('PDF de currículo gerado com sucesso');
        })
        .catch(error => {
            ToastMessage.warning('Problemas ao gerar currículo em PDF');
        })
        .finally(() => setLoading(false));
    }

    return (
        <>
        {loading && <Loading/>}
        {!loading && <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Currículos" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            <GridList modelName={"Currículo"} 
                                editModelRoute={'/resumes'}
                                newModelRoute={'/resumes/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'resumes'}
                                enableActions={true}
                                addCustomAction={{icon: 'file-pdf', onClick: pdfGenerate}}
                                columns={[
                                { name: 'name', title: 'Nome' },
                                { name: 'email', title: 'Email' },
                                { name: 'phone', title: 'Telefone' },
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>}
        </>
    );
};

