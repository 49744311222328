import React, { useState, useEffect, useRef, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import debounce from 'lodash.debounce';
import {
    PagingState,
    CustomPaging,
    FilteringState,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableFilterRow,
    DragDropProvider, 
    Toolbar,
    ExportPanel,
    TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';

import { GridExporter } from '@devexpress/dx-react-grid-export';
import api from '../../services/api';
import { useHistory } from "react-router-dom";
import { Divider, Button } from '@material-ui/core';
import {ActionsContainer} from './ActionsContainer';
import { Loading } from '../Loading/Loading';
import { useSelector, useDispatch } from 'react-redux';
import GridListActions from '../../store/ducks/GridList';
import saveAs from 'file-saver';

const Cell = (props) => {
    if (props.column.filter === false) {
        return <TableFilterRow.Cell {...props}>
        &nbsp;
            </TableFilterRow.Cell> 
        }
    return (<TableFilterRow.Cell {...props} />);
};

const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
};

export const GridList = (props) => {
    const columns = props.columns;
    const pristine = props.pristine;
    const dispatch = useDispatch();

    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize] = useState(30);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lastQuery, setLastQuery] = useState();
    const [filters, setFilters] = useState([]);
    const [value, setValue] = useState('');
    const defaultDebounceTime = 1000;
    const endpoint = props.endpoint;
    const history = useHistory();
    const newModelRoute = props.newModelRoute;
    const modelName = props.modelName;
    const editModelRoute = props.editModelRoute;
    const deleteCallback = props.deleteCallback;
    const deleteCallbackFailure = props.deleteCallbackFailure;
    const enableActions = props.enableActions;
    const addCustomAction = props.addCustomAction;
    const disableNewButtom = props.disableNewButtom || false;
    const orders = props.orders;
    const hiddenUpdateButton = props.hiddenUpdateButton || false;
    const hiddenDeleteButton = props.hiddenDeleteButton || false;
    const hiddenBackButton   = props.hiddenBackButton || false;
    const pkid = props.pkid || '';
    const isSelected = props.isSelected || false;
    const tableWidth = props.tableWidth;
    const tableHeight = props.tableHeight;
    const gridFilter = useSelector(state => state.gridList.filter);
    const loadMasterDetail = useSelector(state => state.gridList.loadMasterDetail);
    const [created, setCreated] = useState(false);
    const [lastFilter, setLastFilter] = useState('');
    const exporterRef = useRef({});
    const [selectedRows, setSelectedRows] = useState([]);

    const [selection, setSelection] = useState([]);
    const vtRef = useRef();
    const [leftColumns] = useState(['actions']);
    const [tableColumnExtensions, setTableColumnExtensions] = useState([]);

    const [filteringStateColumnExtensions, setFilteringStateColumnExtensions] = useState([]);

    const startExport = useCallback((options) => {

        exporterRef.current.props.rows.forEach((obj) => {
            delete obj.actions;
        })
        
        delete exporterRef.current.props.columns[0];
        
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);


    const TableCell = ({ tableRow, ...restProps }) => {
        return (
          <Table.Cell
            {...restProps}
            onClick={() => {

            }}
            style={{padding: '4px'}}
          />
        );
      };

    const getQueryString = () => { 
              
        return `${endpoint}?pageSize=${pageSize}&page=${currentPage}&filter=${gridFilter?gridFilter:[]}&order=${orders ? orders:[]}`;
    };

    const loadData = () => {
        const queryString = getQueryString();
        if (queryString !== lastQuery && !loading) {
            setLoading(true);
            api.get(queryString)
                .then((response) => {
                    const data = response.data;
                    if (enableActions) {
                        data.rows.forEach((row) => 
                            row['actions'] = 
                            <ActionsContainer 
                                row={row}
                                hiddenUpdateButton={hiddenUpdateButton}
                                hiddenDeleteButton={hiddenDeleteButton}
                                url={editModelRoute}
                                deleteCallback={innerDeleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                deleteUrl={endpoint}
                                modelName={modelName}
                                pkid={pkid}
                                addCustomAction={addCustomAction}
                            />
                        );
                    }
                    setRows(data.rows);
                    setTotalCount(data.count);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
            setLastQuery(queryString);
        }
    };

    const innerDeleteCallback = () => {
        deleteCallback();
        const pathname = window.location.pathname;
        history.push("/");
        history.push(pathname);
    }

    const getFilterColumns = () => {
        let filteringColumns = filteringStateColumnExtensions;
        columns.forEach((column) => {
            if (column.filter === false) {
                filteringColumns.push(
                    {columnName: column.name, filteringEnabled: false}
                )
            }
        })
        setFilteringStateColumnExtensions(filteringColumns);
    }

    useEffect(() => {

        async function anyNameFunction() {

            await loadData();
            
            if (!loadMasterDetail ){ 
                await filterListFunc();
            }
            
          }

          anyNameFunction();

    });

    const debouncedSave = useCallback(
		debounce(nextValue => {
            setFilters(nextValue);
        }, defaultDebounceTime),
		[],
	);

    const handleChance = event => {
        const nextValue = event;
		setValue(nextValue);
		debouncedSave(nextValue);
    }

    const newModelRouteClick = () => {
        history.push(newModelRoute)
        
    };

    if (enableActions) {
        if (columns.filter((column) => column.name === 'actions').length === 0) {
            columns.unshift({name: 'actions', title: 'Actions', filter: false, width:10});
        }  
    }

    const filterListFunc = () => {
        let filter = filters.reduce((acc, { columnName, value }) => {
            const column = columns.filter(c => c.name === columnName)[0];
            const {type} = column;
            acc.push(`[${columnName},${type || 'contains'},${encodeURIComponent(value)}]`);
            return acc;
        }, []).join(',and,');

        if ((lastFilter !== filter)) {
            dispatch(GridListActions.setFilter(filter));
        }

        setLastFilter(filter);

    }

    if (!created) {
        dispatch(GridListActions.setFilter([]));
        getFilterColumns()
        
        let actionsWidth = 0;
        !hiddenDeleteButton?actionsWidth=70:actionsWidth=0;
        !hiddenUpdateButton?actionsWidth+=70:actionsWidth=80;
        addCustomAction?actionsWidth+=30:actionsWidth=actionsWidth;
        !enableActions?actionsWidth=0:actionsWidth=actionsWidth;

        let res = [ {columnName: 'actions', align: 'left', width: actionsWidth}, ];
        columns.forEach( (col) => {

            if (col.hidden){
                col.width = 1
            }

            if (col.title !== 'Actions') {
                 res.push({columnName:col.name, align:'left', width:col.width?col.width:250});
            }

        });

        setTableColumnExtensions(res);

        setCreated(true);
        
    }

    const getRowId = row => row.id;

    return (
        <div>
            {!disableNewButtom && <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={newModelRouteClick}
            >
                Novo(a) {modelName}
            </Button>}
            <Divider />
            <Paper style={{ position: 'relative' , width: tableWidth, maxWidth: '100%' }}> 
                <Grid 
                    rows={rows}
                    columns={columns}
                    getRowId={getRowId}
                >

                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={setCurrentPage}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={totalCount} />
                    {/* <FilteringState onFiltersChange={setFilters} /> */}
                    <FilteringState onFiltersChange={handleChance}/>
                    <SelectionState 
                              selection={selection}
                              onSelectionChange={setSelection}
                    />

                    <DragDropProvider />
                    <IntegratedSelection />
                    
                    <Table cellComponent={TableCell} columnExtensions={tableColumnExtensions} />
                    <TableHeaderRow />
                    <TableFilterRow cellComponent={Cell} />
                    <TableFixedColumns
                        leftColumns={leftColumns}
                    />
                    <PagingPanel />
                    <Toolbar />
                    <ExportPanel startExport={startExport} />
                </Grid>

                <GridExporter
                        ref={exporterRef}
                        columns={columns}
                        rows={rows}
                        selection={selection}
                        onSave={onSave}
                    />
                {loading && <Loading/>}
            </Paper>
                {!hiddenBackButton &&
                <Button type="button" style={{margin:15}} color="inherit" variant="contained" disabled={pristine} onClick={() => {history.goBack()}}>
                    Voltar
                </Button>
                }
            

        </div>
        
    );
};
