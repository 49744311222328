import { createTextMask, createNumberMask } from 'redux-form-input-masks';

export const cpfMask = (callback) => createTextMask({
    pattern: '999.999.999-99',
    onChange: cpf => {
        if (cpf && (cpf.length === 11)) {
            callback && callback(cpf);
        }
    }
})

export const cnpjMask = (callback) => createTextMask({
    pattern: '99.999.999/9999-99',
    onChange: cnpj => {
        if (cnpj && (cnpj.length === 14)) {
            callback && callback(cnpj);
        }
    }
})

export const phoneMask = createTextMask({
    pattern: '(99)99999-9999',
})

export const cepMask = createTextMask({
    pattern:'99999-999'
})

export const moneyMask = createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
    locale: 'pt-BR',
})

export const numberMask = createNumberMask({
    prefix: '',
    decimalPlaces: 2,
    locale: 'pt-BR',
})

export const numberMaskNoDescimal = createNumberMask({
    prefix: '',
    decimalPlaces: 0,
    locale: 'pt-BR',
})
export const newPlateVehicleMask = createTextMask({
    pattern: 'AAA9A99',
})

export const plateVehicleMask = createTextMask({
    pattern: 'AAA-9999',
})

export const locationNumberMask = createNumberMask({
    prefix: '',
    decimalPlaces: 5
})

export const DateMaskDDMMYYYY = createTextMask({
    pattern: '99/99/9999',
})

export const TimeMaskHHMMSS = createTextMask({
    pattern: '99:99:99',
})

export const mphone = (v) => {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }