import React,{useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid, Button } from '@material-ui/core';
import { renderTextField, renderTextAreaField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import AuthState from '../../../auth/Auth';
import apiNotify from '../../../services/apiNotify';

const formName = 'SendPush';
const modelName = 'sendpush';
const label = 'Envio de PushNotifications';

let Form = (props) => {
    const [entity, setEntity] = useState({});
    const [msgPush, setMsgPush] = useState('');
    const [titlePush, setTitlePush] = useState('');
	const [userLogado, setUserLogado] = useState({});

    const afterLoadEntity = (entity) => {
        setEntity(entity);

        AuthState.getUserInfo().then( (luser) => {
            setUserLogado(luser)
        });

        if (entity) {
            setTitlePush(entity.title)
            setMsgPush(entity.message)
        }
        
     
    }    

    const handleSendNotification = (e) => {        
        let body = {
            users: userLogado.id,
            company: entity.companyId,
            owner: userLogado.id,
            title: titlePush,
            message:  msgPush,
            notificationId: entity.id
        };
        
        apiNotify.post('/notifications/segments',body)
        .then((resp) =>{
            // console.log(resp)
        })

    }      


    const handleZapSendNotification = (e) => {        
        let body = {
            condominium:'Hestia',
            users: userLogado.id,
            company: entity.companyId,
            owner: userLogado.id,
            title: titlePush,
            message:  msgPush,
            notificationId: entity.id,
            to: '83988179158'
            // to: '83996385300' //edvaldo
            // to: '83982108844'
             
        };
        
        apiNotify.post('/whatsapp',body)
        .then((resp) =>{
            // console.log(resp)
        })

    }      
    const upper = value => value && value.toUpperCase()
    
    // const afterLoadEntity = (entity) => {
    //     setEntity(entity);
    // }

    // const afterInitialState = () => {
    //     setEntity({});
    // }

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            // customAfterInitialState={afterInitialState}
            content={
                
                <Grid container spacing={3} wrap="wrap">

                    
                        
                            <Grid item xs={12} >
                                <Field 
                                    name="title" 
                                    type="text" 
                                    component={renderTextField} 
                                    label="Titulo" 
                                    // normalize={upper} 
                                    style={{ maxWidth: '100%', width: 700 }} 
                                    onBlur={(e) => {(e.target.value !=='')?setTitlePush(e.target.value):setTitlePush('Mersinho Conecta')}} 
                                    
                                    />
                            </Grid>

                            <Grid item xs={12} >
                                <Field 
                                    name="message" 
                                    type="text" 
                                    component={renderTextAreaField} 
                                    onBlur={(e) => {(e.target.value !=='')?setMsgPush(e.target.value):setMsgPush('Favor realizar o resgistro da atividade atual')}} 
                                    label="Texto da Notificação" 
                                    // normalize={upper} 
                                    style={{ maxWidth: '100%', width: 700 }} 
                                    
                                    />
                            </Grid>   

                            <Grid item xs={6}>
                                    <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleSendNotification}
                                    >
                                    Enviar Push
                                </Button>
                            </Grid>  
                        

                            <Grid item xs={6}>
                                    <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleZapSendNotification}
                                    >
                                    Enviar Zap
                                </Button>
                            </Grid>  


                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{}
})(Form);

export default Form;

