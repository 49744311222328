import React,{useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid, Button } from '@material-ui/core';
import { renderTextField, renderTextAreaField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import AuthState from '../../../auth/Auth';
import apiNotify from '../../../services/apiNotify';
import { moneyMask } from '../../../services/masks';
import { isMobile } from 'react-device-detect';

const formName = 'procMedicos';
const modelName = 'procMedicos';
const label = 'Procedimentos Médicos';

// id, 
// titulo, 
// descricao, 
// codigo, 
// valor, 
// updated_at, 
// created_at

let Form = (props) => {
    const [entity, setEntity] = useState({});
    const [msgPush, setMsgPush] = useState('');
    const [titlePush, setTitlePush] = useState('');
	const [userLogado, setUserLogado] = useState({});

    const afterLoadEntity = (entity) => {
        setEntity(entity);

        AuthState.getUserInfo().then( (luser) => {
            setUserLogado(luser)
        });

        if (entity) {
            setTitlePush(entity.title)
            setMsgPush(entity.message)
        }
        
     
    }    


    const upper = value => value && value.toUpperCase()
    
    // const afterLoadEntity = (entity) => {
    //     setEntity(entity);
    // }

    // const afterInitialState = () => {
    //     setEntity({});
    // }

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            // keepUpdateAfterSubmit={true}
            // customAfterInitialState={afterInitialState}
            content={
                
                <Grid container spacing={3} wrap="wrap">
                        
                    <Grid item xs={12} >
                        <Field 
                            name="titulo" 
                            type="text" 
                            component={renderTextField} 
                            label="Titulo" 
                            normalize={upper} 
                            style={{ maxWidth: '100%', width: 700 }} 
                            
                            />
                    </Grid>

                    <Grid item xs={12} >
                        <Field 
                            name="descricao" 
                            type="text" 
                            normalize={upper} 
                            component={renderTextAreaField} 
                            label="Texto da Notificação" 
                            style={{ maxWidth: '100%', width: 700 }} 
                            
                            />
                    </Grid>   


                    <Grid item xs={isMobile?12:6}>
                        <Field name="codigo" type="text" component={renderTextField} label="Código" normalize={upper} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>


                    <Grid item xs={isMobile?12:6}>
                        <Field name="valor" type="text" component={renderTextField} label="Valor"  style={{ maxWidth: '100%', width: 350 }} {...moneyMask}/>
                    </Grid>                                         


                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{}
})(Form);

export default Form;

