import { TableCell, TableRow, TextField } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    ['&.thead']: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral,
    },
    ['&.tbody']: {
      fontSize: 14,
    },
}));

const FilterableColumn = ({field, defaultValue, onChange}) => {
    const [value, setValue] = useState(defaultValue)
    const upper = value => value && value.toUpperCase()
    

    const {filterable, name, title} = field
    return filterable ? 
        <TableRow>
            <StyledTableCell>
                <TextField name={name}  type="text" label={title} style={{maxWidth: '100%' }} normalize={upper}  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                    setValue(e.target.value.toUpperCase());
                    let obj = {}
                    obj[name] = e.target.value
                    if (onChange) onChange(obj)
                }} defaultValue={defaultValue} />
            </StyledTableCell>
        </TableRow>
        : 
        <></>
}

export default FilterableColumn;