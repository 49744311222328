import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,  
  Divider,
  Button,
  IconButton,
  Grid,
  Typography 
} from '@material-ui/core';
import AuthState from '../../../../auth/Auth';
import { useHistory } from 'react-router';

import Icon from '../../../../components/icon/icons';


//import { getToken, deleteToken } from "../../../../services/firebaseNotification/index";

// import LOCAL_URL from '../../../../services/qrCodeConfig'

const useStyles = makeStyles(() => ({
  root: {}
}));

const Admin = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false)


  const handleClick = (e) => {
    AuthState.getUserInfo().then( (luser) => {
      history.push(`/escalasAtividades`);
    })    
  }
  
  const handleRegistrar = (e) => {
    history.push(`/escalasAtividadesIndividual`);
  }   

  
  const handleHistorico = (e) => {
    history.push(`/geolocalizacao`);
  } 
  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="Menu do Lider"
          // subheader="Exibir Cracha"
          
        />
        <Divider />

        <Grid container spacing={3} wrap="wrap">

            <Grid direction="column" justifyContent="center" alignItems="center"  >              
              <Button
                color="primary"
                variant="outlined"
                style={{ maxWidth: '100%', width: 120, margin:15, height:70}}
                onClick={handleClick}
              >                
                <Icon icon='users' size='36'/>     
                
              </Button>
              <Typography align="center" variant="h4" onClick={handleClick} >
                Grupo Político
              </Typography> 
            </Grid>               

        </Grid>
          

        <Divider style={{marginTop:50}} />          
 

      </form>
    </Card>
  );
};

Admin.propTypes = {
  className: PropTypes.string
};

export default Admin;
