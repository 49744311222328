import React ,{useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField, renderTextAreaField, renderSelectField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import api from '../../../services/api';
import { ToastMessage } from '../../../layouts';
import { isMobile } from 'react-device-detect';

const formName = 'VagasEmpregos';
const modelName = 'VagasEmpregos';
const label = 'Vagas de Emprego';

let Form = (props) => {
    const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()


    const [loadingResponsavel, setLoadingResponsavel] = useState(false);
    const [responsavelList, setResponsavelList] = useState([]);  

    const [loadingBeneficiario, setLoadingBeneficiario] = useState(false);   
    const [beneficiarioList, setBeneficiarioList] = useState([]); 
    
    const afterLoadEntity = (entity) => {
        setEntity(entity);
    }

    
    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name}</option>);
            })            
            return (finalList);
        }
    }     
    
    const getResponsaveisList = () => {

        if (!loadingResponsavel) {
            setLoadingResponsavel(true);
            api.get('/users/responsavel?pageSize=-1').then(list => {


                let result = [];
                // console.log(list.data.rows);

                list.data.rows.forEach((item) => {
                    result.push(item.user)
                });
                // console.log(result);
                setResponsavelList(result);                
            });
        }
    }       

    const getSecretariasList = () => {

        if (!loadingBeneficiario) {
            setLoadingBeneficiario(true);
            api.get('/users/contato?pageSize=-1').then(list => {
                setBeneficiarioList(list.data.rows);                
            });
        }
    }  


    getResponsaveisList(); 
    getSecretariasList();

    // const afterInitialState = () => {
    //     setEntity({});
    // }

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            // customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={isMobile?12:6}>
                        <Field name="beneficiario" component={renderSelectField} label="Beneficiário" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(beneficiarioList)}
                        </Field>
                    </Grid>   

                    <Grid item xs={isMobile?12:6}>
                        <Field name="ownerId" component={renderSelectField} label="Responsável" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(responsavelList)}
                        </Field>
                    </Grid>  

                    <Grid item xs={12}>
                        <Field 
                            name="titulo"
                            type="text"
                            component={renderTextField}
                            label="Nome da Vaga"
                            style={{ width: 700, maxWidth:'100%' }}
                            normalize={upper}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Field
                            name="descricao"
                            type="text"
                            component={renderTextAreaField}
                            label="Descrição"
                            style={{ width: 700 , maxWidth:'100%' }}
                            normalize={upper}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Field
                            name="necessario"
                            type="text"
                            component={renderTextAreaField}
                            label="Habilidades Necessárias"
                            style={{ width: 700 , maxWidth:'100%' }}
                            normalize={upper}
                        />
                    </Grid>       

                    <Grid item xs={12}>
                        <Field
                            name="desejado"
                            type="text"
                            component={renderTextAreaField}
                            label="Habilidades Desejadas"
                            style={{ width: 700 , maxWidth:'100%' }}
                            normalize={upper}
                        />
                    </Grid>  

                    <Grid item xs={12}>
                        <Field
                            name="observacoes"
                            type="text"
                            component={renderTextAreaField}
                            label="Observações"
                            style={{ width: 700 , maxWidth:'100%' }}
                            normalize={upper}
                        />
                    </Grid>


                    <Grid item xs={isMobile?12:6}>
                        <Field
                            name="status"
                            type="text"
                            component={renderSelectField}
                            label="Status"
                            style={{ width: 350 , maxWidth:'100%' }}
                            normalize={upper}
                        >
                            <option value={'D'}>Disponível</option>
                            <option value={'O'}>Ocupada</option>
                            <option value={'C'}>Cancelada</option>
                        </Field>                                                
                    </Grid>    

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{}
})(Form);

export default Form;

