import React, {useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid, CardHeader, Button  } from '@material-ui/core';
import { renderTextField, renderSelectField, renderCheckbox} from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { UsersList } from './EscalasAtividadesUsersList';
import { ToastMessage } from '../../../layouts';

import api from '../../../services/api';
import { numberMaskNoDescimal } from '../../../services/masks';
import InputLabel from '@material-ui/core/InputLabel';
import { isMobile } from 'react-device-detect';
import { useHistory } from "react-router-dom";
import Icon from '../../../components/icon/icons';

const formName = 'EscalasAtividadesIndividuais';
const modelName = 'escalasAtividadesIndividual';
const label = 'Grupo Politico';

// const isRequired = () => {
//     return {required: true}
// }

let Form = (props) => {
    // const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    const [secretariaList, setSecretariaList] = useState([]); 
    const [entity, setEntity] = useState({}); 
    const history = useHistory();
    const [addUserId, setAddUserId] = useState(0); 
    const [loadingUser, setLoadingUser] = useState(false);       
    const [userList, setUserList] = useState([]); 
    const [escalasAtividadesId, setEscalasAtividadesId] = useState(''); 
    
    
     
    const [showUsers, setShowUsers] = useState(false); 
    
    const afterLoadEntity = (entity) => {
        setEntity(entity);        
        setShowUsers(entity.id !== undefined);
        getUserList(entity.escalasAtividadesId);

        // console.log(entity.escalasAtividadesId);
        // console.log('entity.escalasAtividadesId');
        if (entity.escalasAtividadesId){
            // console.log('entrei!')
            setEscalasAtividadesId(entity.escalasAtividadesId)
        }
        
        
    }    

    const changeNomeUser = (e) => {
        if (e.target.value) setAddUserId(e.target.value)
    }     

    const addUser = (e) => {
        e.preventDefault();

        const body = {
            userId: addUserId,
            escalasAtividadesId: entity.id
        }

        api.post(`escalasAtividades/${escalasAtividadesId}/user`, body)
        .then(response => {
            setShowUsers(false);
            setShowUsers(true);

            const pathname = window.location.pathname;
            history.push("/");
            history.push(pathname);  

            ToastMessage.success('Contato adicionado com sucesso');
        })
        .catch(error => {
            console.log(error.message)
            ToastMessage.warning('Problemas ao inserir Contato na Escala');
        })
    }   
    
    const getUserList = (id) => {
        if (!loadingUser) {
            setLoadingUser(true);
            api.get(`/users/contatos/${id}`).then(list => {                
                setUserList(list.data.rows);
            });
        }
    }    
    
    // const afterLoadEntity = (entity) => {
    //     setEntity(entity);
    // }

    // const afterInitialState = () => {
    //     setEntity({});
    // }  

    const getSecretariasList = () => {

        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/secretarias?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);                
            });
        }
    }        

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name}</option>);
            })
            return (finalList);
        }
    }    

    getSecretariasList();
    // getUserId();


    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            hiddenNewButton={true}
            // hiddenSaveButton={true}
            // customAfterInitialState={afterInitialState}
            
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12}>
                        <Field name="escalasAtividade.descricao" type="text" component={renderTextField} label="Descrição" normalize={upper} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                    <InputLabel>Data Inicial</InputLabel>
                        <Field name="escalasAtividade.dataInicial" type="date" component={renderTextField} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                    <InputLabel>Data Final</InputLabel>
                        <Field name="escalasAtividade.dataFinal" type="date" component={renderTextField} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>
{/* 
                    <Grid item xs={isMobile?12:4} >
                        <CardHeader title="Primeiro Turno (Manhã)"/>

                        <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                                <Field disabled={true} name="escalasAtividade.horaInicialT1" type="text" component={renderTextField} label="Hora Entrada" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal}/>
                            </Grid>   

                            <Grid item xs={6}>
                                <Field disabled={true} name="escalasAtividade.horaFinalT1" type="text" component={renderTextField} label="Hora Saída" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal}/>
                            </Grid>
                        </Grid>
                    </Grid>     

                    <Grid item xs={isMobile?12:4} >
                        <CardHeader title="Segundo Turno (Tarde)"/>

                        <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                                <Field disabled={true} name="escalasAtividade.horaInicialT2" type="text" component={renderTextField} label="Hora Entrada" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal} />
                            </Grid>   

                            <Grid item xs={6}>
                                <Field disabled={true} name="escalasAtividade.horaFinalT2" type="text" component={renderTextField} label="Hora Saída" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal} />
                            </Grid>
                        </Grid>
                    </Grid>  

                    <Grid item xs={isMobile?12:4} >                        
                        <CardHeader title="Terceiro Turno (Noite)"/>

                        <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                                <Field disabled={true} name="escalasAtividade.horaInicialT3" type="text" component={renderTextField} label="Hora Entrada" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal}/>
                            </Grid>   

                            <Grid item xs={6}>
                                <Field disabled={true} name="escalasAtividade.horaFinalT3" type="text" component={renderTextField} label="Hora Saída" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal} />
                            </Grid>
                        </Grid>
                    </Grid>                                            

                    <Grid item xs={12} >
                        <CardHeader title="Dia da Semana"/>
                        <Grid container direction="row" >
                            <Grid item xs={'auto'}>
                                <Field disabled={true} name="escalasAtividade.domingo" type="text" component={renderCheckbox} label="Domingo" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field disabled={true} name="escalasAtividade.segunda" type="text" component={renderCheckbox} label="Segunda" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field disabled={true} name="escalasAtividade.terca" type="text" component={renderCheckbox} label="Terça" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field disabled={true} name="escalasAtividade.quarta" type="text" component={renderCheckbox} label="Quarta" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field disabled={true} name="escalasAtividade.quinta" type="text" component={renderCheckbox} label="Quinta" style={{ maxWidth: '100%', width: 100 }}   />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="escalasAtividade.sexta" type="text" component={renderCheckbox} label="Sexta" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="escalasAtividade.sabado" type="text" component={renderCheckbox} label="Sábado" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>                                                                                                                                               

                        </Grid>
                    </Grid>  */}

                    <Grid item xs={isMobile?12:6}>
                        <Field name="escalasAtividade.secretariaId" component={renderSelectField} label="Local de Votação" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                    </Grid> 

                    <Grid item xs={isMobile?12:6}>
                        <Field label="status" name="escalasAtividade.status" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'A'}>Ativo</option>
                            <option value={'I'}>Inativo</option>
                        </Field>
                    </Grid>                      
                   

                    {showUsers && entity && entity.id &&                    
                        <Grid item  container xs={12} spacing={2} wrap="wrap" >
                            <Grid item xs={12}>
                                <CardHeader title="Incluir Contatos no Grupo Político"/>
                            </Grid>

                            <Grid container justify="flex-start"  >
                                <Grid item xs={12} >
                                    <InputLabel style={{marginLeft:15 }}>Contato</InputLabel>
                                    <Field 
                                        name="userId" 
                                        component={renderSelectField} 
                                        // label="Usuário" 
                                        style={{marginLeft:10, maxWidth:'100%', width: 350 }}
                                        onChange={changeNomeUser}
                                        >
                                        {renderSelection(userList)}
                                    </Field>

                                    <Button 
                                        style={{ marginLeft:10, width: 250, maxWidth:'100%', height:53 }} 
                                        onClick={addUser} 
                                        color="secondary" 
                                        variant="outlined"
                                        endIcon={ <Icon icon='person_add_alt_1' size='24' color="blue"/>   }
                                        >                                                    
                                        Adicionar                                       
                                    </Button>

                                </Grid> 
                            </Grid>                          
                            {console.log(escalasAtividadesId)}
                            { (escalasAtividadesId !== '') && (escalasAtividadesId) &&
                                <UsersList escalaId={escalasAtividadesId}/> 
                            }
                             {/* style={{  maxWidth:'100%'}} */}
                        </Grid>                        
                    }

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        'status':'A',
    }
})(Form);

export default Form;

