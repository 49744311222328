import React from 'react';
import './styles/home.css'
import fotoPerfil1 from './assets/placas04.webp'
import transicao from './assets/Path-2.webp'
import transicao1 from './assets/Design_sem_nome.png'
import imgFundo from './assets/home-mersinho-img.webp'

function Home () {

    return(
    <section className="home">

        <div className="logo-home">

            <picture className="fotoPerfil">
                    <source media="(max-width: 750px)" srcset={fotoPerfil1} type="image/png"/>
                    <img src={imgFundo} alt="fotomersinho"/>
            </picture>

            <div className="logo-transicao">
                <picture >
                    <source media="(max-width: 1400px)" srcset={transicao} type="image/png"/>
                    {/* <img src={transicao1} alt="fotomersinho" height="100" /> */}
                </picture>

            </div>
        </div>


    </section>
    );
};

export default Home;
