import React, { Component } from 'react';
import { Button, Divider } from '@material-ui/core';
import { api } from '../../services';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import './uploadfile.css';
import { Label } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { ToastMessage } from '../../layouts';

class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loaded: 0,
            list: props.list || []
        }

    }

    checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        const types = ['application/pdf'/*, 'image/png', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/zip'*/]
        // loop access array
        for (let x = 0; x < files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err[x] = files[x].type + ' is not a supported format\n';
            }
        };
        for (let z = 0; z < err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            ToastMessage.error(err[z]);
            event.target.value = null
        }
        return true;
    }

    maxSelectFile = (event) => {
        let files = event.target.files
        if (files.length > 3) {
            const msg = 'Only 3 images can be uploaded at a time'
            event.target.value = null
            ToastMessage.warn(msg)
            return false;
        }
        return true;
    }

    checkFileSize = (event) => {
        let files = event.target.files
        let size = 2000000
        let err = [];
        for (var x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type + 'is too large, please pick a smaller file\n';
            }
        };
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            ToastMessage.error(err[z])
            event.target.value = null
        }
        return true;
    }

    onChangeHandler = event => {
        var files = event.target.files
        if (this.maxSelectFile(event) && this.checkMimeType(event) && this.checkFileSize(event)) {
            // if return true allow to setState
            this.setState({
                selectedFile: files,
                loaded: 0
            })
        }
    }

    onSuccess = (response, file, originalFilename, filetype) => {
        return api.put(`/${this.props.context}/upload/${this.props.entity.id}`, 
            {
                ownerId: this.props.entity.id,
                context: this.props.context,
                originalFilename: originalFilename,
                filename: file.filename,
                filetype: filetype
            }
        )
            .then((response) => {
                let newFiles = [];
                this.state.list.forEach((file) => {
                    newFiles.push(
                        {
                            id: file.id,
                            context: this.props.context,
                            url: file.url,
                            originalFilename: file.originalFilename,
                            filename: file.filename
                        }
                    )
                })

                newFiles.push({
                    id: response.data.id,
                    context: this.props.context,
                    url: response.data.url,
                    originalFilename: this.state.selectedFile[0].name,
                    filename: response.data.filename
                })

                this.setState({
                    selectedFile: null,
                    loaded: 0,
                    list: newFiles
                });

                ToastMessage.success('Upload de arquivo concluído.');
                if (this.props.onSuccess) {
                    this.props.onSuccess(response.data, originalFilename)
                }
                return response;
            })
            .catch((error) => console.error(error))
    }

    selectFile = () => {
        const input = document.getElementsByClassName("hiddenInputFile");
        if (input && input[0]) {
            input[0].click();
        }
    }

    onClickHandler = () => {
        const data = new FormData()
        if (this.state.selectedFile) {
          for (let x = 0; x < this.state.selectedFile.length; x++) {
            let file = this.state.selectedFile[x];
            let fileExtension = file.name.split('.')[file.name.split('.').length - 1];
            data.append('file', file);
            let ctx = this.props.context.replace("/", "%2F");

            api.get(`/upload/${ctx}`, {params: {fileExtension: fileExtension, contentType: file.type, originalFilename: file.name}})
            .then(res => {
                let image = {url: res.data.url, file: file, filename: res.data.filename, originalFilename: file.name }
                
                api.doUploadFile(image, this.onSuccess, file.name)
            })
            .catch(error => {
                ToastMessage.error(error);
            })
          }
        }
    }

    stripFileFromList = (file) => {
        let newFiles = this.state.list.filter((fl) => fl.id !== file.id);
        this.setState({list: newFiles || []})
    }

    doDeleteFile = (file) => {
        var ctx = this.props.context.replace("/", "%2F");
        const encodeUri = encodeURI(`${ctx}%2F${file.filename}`)
        api.get(`/upload/delete?context=${encodeUri}`)
        .then((response) => {
            api.doDeleteFile(
                this.props.context,
                this.props.entity,
                {
                    id: file.id,
                    context: this.props.context,
                    url: response.data.url,
                    file: undefined
                }
            ).then((response) => {
                this.stripFileFromList(
                    {
                        id: file.id,
                        context: file.context,
                        url: undefined,
                        file: undefined,
                    }
                )
            })
            .catch((error) => console.error(error))
        })
    }

    deleteFile = (file) => {
        confirmAlert({
            title: 'Confirma exclusão',
            message: 'Tem certeza que deseja excluir esse arquivo?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        this.doDeleteFile(file)
                    }
                },
                {
                    label: 'Não'
                }
            ]
        });
    }

    renderFiles = (files) => {
        let output = []
        files.forEach((file) =>
            output.push(
                <div>
                    <IconButton aria-label="Delete" color="primary" variant="contained" component="span" onClick={() => {this.deleteFile(file)}}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    <a href={file.url} target="_blank" rel="noopener noreferrer">{file.originalFilename}</a>
                </div>
            ))
        
        return output;
    }

    render() {
        const uploadButtonEnabled = (this.state.selectedFile && this.state.selectedFile.length >  0);
        
        return (
            <div class="container">
                <Divider   style={{margin:20, height:3}}/>
                <div class="row">{this.props.label}</div>
                <div class="column">
                    {this.renderFiles(this.state.list)}
                </div>
                <div class="row">
                    <div class="offset-md-3 col-md-6">
                        <IconButton aria-label="add" onClick={this.selectFile}>
                            <AddIcon fontSize="large" />
                        </IconButton>
                        <div class="form-group files" className="hidden">
                            <label>Upload Your File </label>
                            <input type="file" class="hiddenInputFile" multiple={false} onChange={this.onChangeHandler} accept="application/pdf" />
                        </div>

                        <div class="form-group" className="progress">
                            <Label>{this.state.selectedFile && this.state.selectedFile.length > 0 && this.state.selectedFile[0].name}</Label>
                        </div>

                        <Button className="crop" disabled={!uploadButtonEnabled} color={uploadButtonEnabled ? "primary" : "inherit"} type="button" variant="contained" onClick={this.onClickHandler}>
                            Upload
                        </Button>

                    </div>
                </div>
            </div>
        );
    }
}

export default UploadFile;
