import React, {useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid, CardHeader, Divider, Button } from '@material-ui/core';
import { renderTextField, renderDatePicker, renderSelectField} from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
// import AuthState from '../../../auth/Auth';
import { UsersList } from './EscalasAtividadesUsersList';
import Icon from '../../../components/icon/icons';
import { ToastMessage } from '../../../layouts';
import { isMobile } from 'react-device-detect';
import { useHistory } from "react-router-dom";

import api from '../../../services/api';
import InputLabel from '@material-ui/core/InputLabel';

const formName = 'EscalasAtividades';
const modelName = 'escalasAtividades';
const label = 'Grupos Politicos';

// const isRequired = () => {
//     return {required: true}
// }

let Form = (props) => {
    // const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    const [secretariaList, setSecretariaList] = useState([]); 
    const [entity, setEntity] = useState({}); 
    const [userList, setUserList] = useState([]);    
    const [loadingUser, setLoadingUser] = useState(false);   
    const [addUserId, setAddUserId] = useState(0);    
    const [showUsers, setShowUsers] = useState(false); 
    const history = useHistory();    
    
    const afterLoadEntity = (entity) => {
        setEntity(entity);
        setShowUsers(entity.id !== undefined);                
        getUserList(entity.id);
        
    }    
    
    const addUser = (e) => {
        e.preventDefault();

        const body = {
            userId: addUserId,
            escalasAtividadesId: entity.id
        }

        api.post(`escalasAtividades/${entity.id}/user`, body)
        .then(response => {
            setShowUsers(false);
            setShowUsers(true);

            const pathname = window.location.pathname;
            history.push("/");
            history.push(pathname);  

            ToastMessage.success('Contato adicionado com sucesso');
        })
        .catch(error => {
            console.log(error.message)
            ToastMessage.warning('Problemas ao inserir Contato na Escala');
        })
    }    

    const getUserList = (id) => {
        if (!loadingUser) {
            setLoadingUser(true);
            api.get(`/users/contatos/${id}`).then(list => {                
                setUserList(list.data.rows);
            });
        }
    }

    const getSecretariasList = () => {

        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/secretarias?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);                
            });
        }
    }        

    const changeNomeUser = (e) => {
        if (e.target.value) setAddUserId(e.target.value)
    }    
    
    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name}</option>);
            })
            return (finalList);
        }
    }    

    getSecretariasList();


    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            // customAfterInitialState={afterInitialState}
            
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12}>
                        <Field name="descricao" type="text" component={renderTextField} label="Descrição" normalize={upper} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                    <InputLabel>Data Criação</InputLabel>
                        <Field name="dataInicial" type="date" component={renderDatePicker} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                    <InputLabel>Data Encerramento</InputLabel>
                        <Field name="dataFinal" type="date" component={renderDatePicker} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    {/* <Grid item xs={isMobile?12:4} >
                        <CardHeader title="Primeiro Turno (Manhã)"/>

                        <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                                <Field name="horaInicialT1" type="text" component={renderTextField} label="Hora Entrada" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal}/>
                            </Grid>   

                            <Grid item xs={6}>
                                <Field name="horaFinalT1" type="text" component={renderTextField} label="Hora Saída" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal}/>
                            </Grid>
                        </Grid>
                    </Grid>     

                    <Grid item xs={isMobile?12:4} >
                        <CardHeader title="Segundo Turno (Tarde)"/>

                        <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                                <Field name="horaInicialT2" type="text" component={renderTextField} label="Hora Entrada" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal} />
                            </Grid>   

                            <Grid item xs={6}>
                                <Field name="horaFinalT2" type="text" component={renderTextField} label="Hora Saída" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal} />
                            </Grid>
                        </Grid>
                    </Grid>  

                    <Grid item xs={isMobile?12:4} >                        
                        <CardHeader title="Terceiro Turno (Noite)"/>

                        <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                                <Field name="horaInicialT3" type="text" component={renderTextField} label="Hora Entrada" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal}/>
                            </Grid>   

                            <Grid item xs={6}>
                                <Field name="horaFinalT3" type="text" component={renderTextField} label="Hora Saída" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal} />
                            </Grid>
                        </Grid>
                    </Grid>                                            

                    <Grid item xs={12} >
                        <CardHeader title="Dia da Semana"/>
                        <Grid container direction="row" >
                            <Grid item xs={'auto'}>
                                <Field name="domingo" type="text" component={renderCheckbox} label="Domingo" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="segunda" type="text" component={renderCheckbox} label="Segunda" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="terca" type="text" component={renderCheckbox} label="Terça" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="quarta" type="text" component={renderCheckbox} label="Quarta" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="quinta" type="text" component={renderCheckbox} label="Quinta" style={{ maxWidth: '100%', width: 100 }}   />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="sexta" type="text" component={renderCheckbox} label="Sexta" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>   

                            <Grid item xs={'auto'}>
                                <Field name="sabado" type="text" component={renderCheckbox} label="Sábado" style={{ maxWidth: '100%', width: 100 }}  />
                            </Grid>                                                                                                                                               

                        </Grid>
                    </Grid>  */}

                    <Grid item xs={isMobile?12:6}>
                        <Field name="secretariaId" component={renderSelectField} label="Local de Votação" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                    </Grid>                       

                    <Grid item xs={isMobile?12:6}>
                        <Field label="status" name="status" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'A'}>Ativo</option>
                            <option value={'I'}>Inativo</option>
                        </Field>
                    </Grid>                      
                                        
                    <Divider />

                    {showUsers && entity && entity.id &&                    
                        <Grid item  container xs={12} spacing={2} wrap="wrap" >
                            <Grid item xs={12}>
                                <CardHeader title="Incluir Contato no Grupo"/>
                            </Grid>

                            <Grid container justify="flex-start"  >
                                <InputLabel style={{marginLeft:15 }}>Contato</InputLabel>
                                <Grid item xs={12} >
                                    <Field 
                                        name="userId" 
                                        component={renderSelectField} 
                                        // label="Usuário" 
                                        style={{marginLeft:10, maxWidth:'100%', width: 350 }}
                                        onChange={changeNomeUser}
                                        >
                                        {renderSelection(userList)}
                                    </Field>

                                    <Button 
                                        style={{ marginLeft:10, width: 250, maxWidth:'100%', height:53 }} 
                                        onClick={addUser} 
                                        color="secondary" 
                                        variant="outlined"
                                        endIcon={ <Icon icon='person_add_alt_1' size='24' color="blue"/>   }
                                        >                                                    
                                        Adicionar                                       
                                    </Button>

                                </Grid> 
                            </Grid>                          

                            <UsersList /> 
                             {/* style={{  maxWidth:'100%'}} */}
                        </Grid>                        
                    }

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{}
})(Form);

export default Form;

