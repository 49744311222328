import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  Settings as SettingsView,
  Dashboad as DashboadView,
  FormUser,
  FormGrant,
  FormProfile,
  FormCompany,
  FormUserCompanyProfile,
  FormCandidato,
  FormConjuge,
  FormEndereco,
  FormFamilia,
  FormDemografico,
  FormDocumento,
  FormExame,
  FormResultado,
  FormSecretaria,
  FormAssunto,
  FormSla,
  FormDemanda,
  PublicFormCandidato,
  Success,
  ResetPasswordSuccess,
  FormConcorrencia,
  FormInscricoes,
  FormDemandaSimples,
  PublicFormRegUsers,
  PublicFormCracha,
  NotFound as NotFoundView,
  Forbidden as ForbiddenView,
  FormRegistroAtividade,
  FormEscalasAtividades,
  FormEscalasAtividadesIndividual,
  FormRegistroAtividadeCfg,
  FormRegistroAtividadeIndividual,
  FormRegistroAtividadesHistorico,
  FormGeolocalizacao,
  FormUserLideranca,
  FormSendpush,
  FormResume,
  FormVagasEmprego,
  FormEventReport,
  FormProcedimentosMedicos
} from "./views";

import { UserList } from "./views/users/components/UserList";
import { SendpushList } from "./views/sendPush/components/SendPushList";
import { GrantList } from "./views/grants/components/GrantList";
import { ProfileList } from "./views/profiles/components/ProfileList";
import { CompanyList } from "./views/companies/components/CompanyList";
import { UserCompanyProfileList } from "./views/userCompanyProfiles/components/UserCompanyProfileList";
import { CandidatoList } from "./views/candidatos/components/CandidatoList";
import { ExameList } from "./views/exames/components/ExameList";
import { ConcorrenciaList } from "./views/concorrencias/components/ConcorrenciaList";
import { InscricoesList } from "./views/inscricoes/components/InscricoesList";
import { ProcedimentosMedicosList } from "./views/procedimentosMedicos/components/ProcedimentosMedicosList";

import { SecretariasList } from "./views/secretarias/components/SecretariaList";
import { AssuntosList } from "./views/assuntos/components/AssuntosList";
import { SlasList } from "./views/slas/components/SlasList";
import { DemandasList } from "./views/demandas/components/DemandasList";
import { DemandaSimplesList } from "./views/demandaSimples/components/DemandaSimplesList";

import { EscalasAtividadesList } from "./views/escalasAtividades/components/EscalasAtividadesList";
import { EscalasAtividadesIndividualList } from "./views/escalasAtividadesIndividual/components/EscalasAtividadesIndividualList";
import { RegistroAtividadeCfgList } from "./views/registroAtividadesCfg/components/RegistroAtividadeCfgList";
import { RegistroAtividadeList } from "./views/registroAtividades/components/RegistroAtividadeList";
import { RegistroAtividadeIndividualList } from "./views/registroAtividadesIndividual/components/RegistroAtividadeIndividualList";
import { RegistroAtividadeHistoricoList } from "./views/registroAtividadesHistorico/components/RegistroAtividadeHistoricoList";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LoginForm from "./components/LoginForm/LoginForm";
import ForgotPasswordForm from "./components/ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "./components/ResetPasswordForm/ResetPasswordForm";

import { UserLiderancaList } from "./views/usersLiderancas/components/UserLiderancaList";
import { UserMultiplicadorList } from "./views/usersMultiplicadores/components/UserMultiplicadorList";
import { VagasEmpregosList } from "./views/vagasEmprego/components/VagasEmpregoList";

import { ResumeList } from "./views/resumes/components/ResumeList";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />

      <RouteWithLayout
        component={FormResultado}
        exact
        layout={MinimalLayout}
        path="/meuexame"
      />

      <RouteWithLayout
        component={ResetPasswordForm}
        exact
        layout={MinimalLayout}
        path="/forgotPassword/:token"
      />

      <RouteWithLayout
        component={ResetPasswordSuccess}
        exact
        layout={MinimalLayout}
        path="/resetPassword/sucesso"
      />

      <RouteWithLayout
        component={PublicFormCandidato}
        exact
        layout={MinimalLayout}
        path="/minhahabitacao"
      />

      <RouteWithLayout
        component={PublicFormRegUsers}
        exact
        layout={MinimalLayout}
        path="/:id/regusuario/"
      />

      <RouteWithLayout
        component={PublicFormCracha}
        exact
        layout={MinimalLayout}
        path="/crachavirtual/:id"
      />


      <RouteWithLayout
        component={Success}
        exact
        layout={MinimalLayout}
        path="/minhahabitacao/sucesso"
      />

      <RouteWithLayout
        component={LoginForm}
        exact
        layout={MainLayout}
        path="/login"
      />

      <RouteWithLayout
        component={ForgotPasswordForm}
        exact
        layout={MainLayout}
        path="/forgotPassword"
      />

      <ProtectedRoute
        component={FormUser}
        exact
        layout={MainLayout}
        path="/users/new"
      />

      <ProtectedRoute
        component={FormUser}
        exact
        layout={MainLayout}
        path="/users/:id"
      />

      <ProtectedRoute
        component={UserList}
        exact
        layout={MainLayout}
        path="/users"
      />

////////////////////////
{/* procmedicos */}      

      <ProtectedRoute
        component={FormProcedimentosMedicos}
        exact
        layout={MainLayout}
        path="/procmedicos/new"
      />

      <ProtectedRoute
        component={FormProcedimentosMedicos}
        exact
        layout={MainLayout}
        path="/procmedicos/:id"
      />

      <ProtectedRoute
        component={ProcedimentosMedicosList}
        exact
        layout={MainLayout}
        path="/procmedicos"
      />      

      {/* <ProtectedRoute
        component={FormUser}
        exact
        layout={MainLayout}
        path="/users/new"
      />

      <ProtectedRoute
        component={FormUser}
        exact
        layout={MainLayout}
        path="/users/:id"
      /> */}

      <ProtectedRoute
        component={UserLiderancaList}
        exact
        layout={MainLayout}
        path="/usersLiderancas"
      />


      <ProtectedRoute
        component={UserLiderancaList}
        exact
        layout={MainLayout}
        path="/usersLiderancas/:type"
      />      

      <ProtectedRoute
        component={UserMultiplicadorList}
        exact
        layout={MainLayout}
        path="/usersMultiplicadores"
      />

      <ProtectedRoute
        component={DashboadView}
        exact
        layout={MainLayout}
        path="/dashboad"
      />      

      <ProtectedRoute
        component={FormEscalasAtividades}
        exact
        layout={MainLayout}
        path="/escalasAtividades/new"
      />

      <ProtectedRoute
        component={FormEscalasAtividades}
        exact
        layout={MainLayout}
        path="/escalasAtividades/:id"
      />

      <ProtectedRoute
        component={EscalasAtividadesList}
        exact
        layout={MainLayout}
        path="/escalasAtividades"
      />  


      <ProtectedRoute
        component={FormEscalasAtividadesIndividual}
        exact
        layout={MainLayout}
        path="/escalasAtividadesIndividual/:id"
      />

      <ProtectedRoute
        component={EscalasAtividadesIndividualList}
        exact
        layout={MainLayout}
        path="/escalasAtividadesIndividual"
      />    



      <ProtectedRoute
        component={FormRegistroAtividadeCfg}
        exact
        layout={MainLayout}
        path="/registroAtividadesCfg/new"
      />

      <ProtectedRoute
        component={FormEventReport}
        exact
        layout={MainLayout}
        path="/eventReports"
      />


      <ProtectedRoute
        component={FormRegistroAtividadeCfg}
        exact
        layout={MainLayout}
        path="/registroAtividadesCfg/:userId/evento"
      />       
              
      <ProtectedRoute
        component={FormRegistroAtividadeCfg}
        exact
        layout={MainLayout}
        path="/registroAtividadesCfg/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeCfgList}
        exact
        layout={MainLayout}
        path="/registroAtividadesCfg"
      />        


        

      <ProtectedRoute
        component={FormRegistroAtividade}
        exact
        layout={MainLayout}
        path="/registroAtividades/new"
      />

      <ProtectedRoute
        component={FormRegistroAtividade}
        exact
        layout={MainLayout}
        path="/registroAtividades/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeList}
        exact
        layout={MainLayout}
        path="/registroAtividades"
      />


      <ProtectedRoute
        component={FormRegistroAtividadesHistorico}
        exact
        layout={MainLayout}
        path="/registroAtividadesHistorico/new"
      />

      <ProtectedRoute
        component={FormRegistroAtividadesHistorico}
        exact
        layout={MainLayout}
        path="/registroAtividadesHistorico/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeHistoricoList}
        exact
        layout={MainLayout}
        path="/registroAtividadesHistorico"
      />


      <ProtectedRoute
        component={FormGeolocalizacao}
        exact
        layout={MainLayout}
        path="/geolocalizacao"
      />

      <ProtectedRoute
        component={FormRegistroAtividadeIndividual}
        exact
        layout={MainLayout}
        path="/registroAtividadesIndividual/new"
      />

      <ProtectedRoute
        component={FormRegistroAtividadeIndividual}
        exact
        layout={MainLayout}
        path="/registroAtividadesIndividual/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeIndividualList}
        exact
        layout={MainLayout}
        path="/registroAtividadesIndividual"
      />





      <ProtectedRoute
        component={FormGrant}
        exact
        layout={MainLayout}
        path="/grants/new"
      />

      <ProtectedRoute
        component={FormGrant}
        exact
        layout={MainLayout}
        path="/grants/:id"
      />

      <ProtectedRoute
        component={GrantList}
        exact
        layout={MainLayout}
        path="/grants"
      />

      <ProtectedRoute
        component={FormProfile}
        exact
        layout={MainLayout}
        path="/profiles/new"
      />

      <ProtectedRoute
        component={FormProfile}
        exact
        layout={MainLayout}
        path="/profiles/:id"
      />

      <ProtectedRoute
        component={ProfileList}
        exact
        layout={MainLayout}
        path="/profiles"
      />

      <ProtectedRoute
        component={FormUserCompanyProfile}
        exact
        layout={MainLayout}
        path="/user/company/profiles/new"
      />

      <ProtectedRoute
        component={FormUserCompanyProfile}
        exact
        layout={MainLayout}
        path="/user/company/profiles/:id"
      />

      <ProtectedRoute
        component={UserCompanyProfileList}
        exact
        layout={MainLayout}
        path="/user/company/profiles"
      />

      <ProtectedRoute
        component={FormCompany}
        exact
        layout={MainLayout}
        path="/companies/new"
      />

      <ProtectedRoute
        component={FormCompany}
        exact
        layout={MainLayout}
        path="/companies/:id"
      />

      <ProtectedRoute
        component={CompanyList}
        exact
        layout={MainLayout}
        path="/companies"
      />

      <ProtectedRoute
        component={FormCandidato}
        exact
        layout={MainLayout}
        path="/candidatos/new"
      />

      <ProtectedRoute
        component={FormCandidato}
        exact
        layout={MainLayout}
        path="/candidatos/:id"
      />

      <ProtectedRoute
        component={CandidatoList}
        exact
        layout={MainLayout}
        path="/candidatos"
      />

      <ProtectedRoute
        component={FormConcorrencia}
        exact
        layout={MainLayout}
        path="/concorrencias/new"
      />

      <ProtectedRoute
        component={FormConcorrencia}
        exact
        layout={MainLayout}
        path="/concorrencias/:id"
      />

      <ProtectedRoute
        component={ConcorrenciaList}
        exact
        layout={MainLayout}
        path="/concorrencias"
      />

      <ProtectedRoute
        component={FormInscricoes}
        exact
        layout={MainLayout}
        path="/inscricoes/new"
      />

      <ProtectedRoute
        component={FormInscricoes}
        exact
        layout={MainLayout}
        path="/inscricoes/:id"
      />

      <ProtectedRoute
        component={InscricoesList}
        exact
        layout={MainLayout}
        path="/inscricoes"
      />

      <ProtectedRoute
        component={FormConjuge}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/conjuge/new"
      />

      <ProtectedRoute
        component={FormConjuge}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/conjuge/:id"
      />

      <ProtectedRoute
        component={FormEndereco}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/endereco/new"
      />

      <ProtectedRoute
        component={FormEndereco}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/endereco/:id"
      />

      <ProtectedRoute
        component={FormFamilia}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/familia/new"
      />

      <ProtectedRoute
        component={FormFamilia}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/familia/:id"
      />

      <ProtectedRoute
        component={FormDemografico}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/demografico/new"
      />

      <ProtectedRoute
        component={FormDemografico}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/demografico/:id"
      />

      <ProtectedRoute
        component={FormDocumento}
        exact
        layout={MainLayout}
        path="/candidatos/:id/documentos"
      />

      <ProtectedRoute
        component={FormExame}
        exact
        layout={MainLayout}
        path="/exames/new"
      />

      <ProtectedRoute
        component={FormExame}
        exact
        layout={MainLayout}
        path="/exames/:id"
      />

      <ProtectedRoute
        component={ExameList}
        exact
        layout={MainLayout}
        path="/exames"
      />

      <ProtectedRoute
        component={FormSecretaria}
        exact
        layout={MainLayout}
        path="/secretarias/new"
      />

      <ProtectedRoute
        component={FormSecretaria}
        exact
        layout={MainLayout}
        path="/secretarias/:id"
      />

      <ProtectedRoute
        component={SecretariasList}
        exact
        layout={MainLayout}
        path="/secretarias"
      />

      <ProtectedRoute
        component={FormAssunto}
        exact
        layout={MainLayout}
        path="/assuntos/new"
      />

      <ProtectedRoute
        component={FormAssunto}
        exact
        layout={MainLayout}
        path="/assuntos/:id"
      />

      <ProtectedRoute
        component={AssuntosList}
        exact
        layout={MainLayout}
        path="/assuntos"
      />

      <ProtectedRoute
        component={FormSla}
        exact
        layout={MainLayout}
        path="/slas/new"
      />

      <ProtectedRoute
        component={FormSla}
        exact
        layout={MainLayout}
        path="/slas/:id"
      />

      <ProtectedRoute
        component={SlasList}
        exact
        layout={MainLayout}
        path="/slas"
      />

    <ProtectedRoute
        component={FormSendpush}
        exact
        layout={MainLayout}
        path="/sendpush/new"
      />

      <ProtectedRoute
        component={FormSendpush}
        exact
        layout={MainLayout}
        path="/sendpush/:id"
      />

      <ProtectedRoute
        component={SendpushList}
        exact
        layout={MainLayout}
        path="/sendpush"
      />      

      <ProtectedRoute
        component={FormDemanda}
        exact
        layout={MainLayout}
        path="/demandas/new"
      />

      <ProtectedRoute
        component={FormDemanda}
        exact
        layout={MainLayout}
        path="/demandas/:id"
      />

      <ProtectedRoute
        component={DemandasList}
        exact
        layout={MainLayout}
        path="/demandas"
      />      

      <ProtectedRoute
        component={FormVagasEmprego}
        exact
        layout={MainLayout}
        path="/vagasEmpregos/new"
      />

      <ProtectedRoute
        component={FormVagasEmprego}
        exact
        layout={MainLayout}
        path="/vagasEmpregos/:id"
      />

      <ProtectedRoute
        component={VagasEmpregosList}
        exact
        layout={MainLayout}
        path="/vagasEmpregos"
      />

      <ProtectedRoute
        component={FormDemandaSimples}
        exact
        layout={MainLayout}
        path="/demandasSimples/new"
      />

      <ProtectedRoute
        component={FormDemandaSimples}
        exact
        layout={MainLayout}
        path="/demandasSimples/:id"
      />

      <ProtectedRoute
        component={DemandaSimplesList}
        exact
        layout={MainLayout}
        path="/demandasSimples"
      />

      <ProtectedRoute
        component={FormResume}
        exact
        layout={MainLayout}
        path="/resumes/new"
      />

      <ProtectedRoute
        component={FormResume}
        exact
        layout={MainLayout}
        path="/resumes/:id"
      />

      <ProtectedRoute
        component={ResumeList}
        exact
        layout={MainLayout}
        path="/resumes"
      />

      <ProtectedRoute
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/home"
      />

      <RouteWithLayout
        component={ForbiddenView}
        exact
        layout={MinimalLayout}
        path="/forbidden"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
