import { api } from "../services";

const authenticate = ({cpf, password, companyId}) => {
    return api.post('login', {cpf, password, companyId})
    .then(response => {
        if (response.status === 200) {
            sessionStorage.setItem('token', 
                response.data.token
            );
            return response.data.token;
        }

        throw new Error(response.message);
    })
    .then(token => {
        return api.get('user/info').then(response => {
            if (response.status === 200) {
                const auth = {
                    isAuthenticated: true, 
                    grants: response.data.grants,
                    userInfo: response.data.user,
                    profile: response.data.profile,
                    company: response.data.company,
                    candidatoId: response.data.candidatoId,
                    companyInfo: response.data.companyInfo
                };

                sessionStorage.setItem('auth', 
                    JSON.stringify(auth)
                );
                return response;
            }
        })
    })
    .catch(error => {
        throw new Error(error);
    })
}

export default authenticate;
