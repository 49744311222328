import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import CardList from '../../../components/CardList/CardList';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const UserMultiplicadorList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    const columns = [
        // { name: 'id', title: '#' },        
        { name: '$user.name$', title: 'Nome', getCellValue: row => row.user ? row.user.name : null , filterable: true, primary: true },
        { name: '$user.wifename$', title: 'Côjuge', getCellValue: row => row.user ? row.user.wifename : null, width: 150, filterable: true, secondary: true },        
        { name: '$user.phone$', title: 'Fone', getCellValue: row => row.user ? row.user.phone : null, width:150 },
        { name: '$user.email$', title: 'e-mail', getCellValue: row => row.user ? row.user.email : null, filterable: true, content: true },
        { name: '$user.cpf$', title: 'CPF', getCellValue: row => row.user ? row.user.cpf : null, width: 1 , filterable: false, avatar: true },
    ]    


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Multiplicadores" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"users"} endpoint={"users/multiplicadores"} columns={columns} pkid={'userId'} />:
                            
                            <GridList modelName={"Usuário"} 
                                editModelRoute={'/users'}
                                newModelRoute={'/users/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'users/multiplicadores'}
                                enableActions={true}
                                pkid={'userId'}
                                columns={[
                                { name: '$user.phone$', title: 'Fone', getCellValue: row => row.user ? row.user.phone : null, width:150 },
                                { name: '$user.name$', title: 'Nome', getCellValue: row => row.user ? row.user.name : null, width:150 },
                                { name: '$user.wifename$', title: 'Cônjuge', getCellValue: row => row.user ? row.user.wifename : null, width:150 },
                                { name: '$user.secretaria$', title: 'Partido', getCellValue: row => row.user ? row.user.secretaria : null, width:150 },
                                { name: '$user.email$', title: 'e-mail', getCellValue: row => row.user ? row.user.email : null}
                            ]}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

