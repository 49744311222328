import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  CardHeader,    
  Grid,
} from '@material-ui/core';
// import AuthState from '../../../auth/Auth';
import { useHistory } from 'react-router';
// import Icon from '../../../components/icon/icons';
// import { isMobile } from 'react-device-detect';
import api from '../../../services/api';
import {  reduxForm } from 'redux-form';
// import { renderTextField, renderSelectField, renderCheckbox } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';

import TreeView from '@mui/lab/TreeView';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { mphone } from '../../../services/masks';

import StyledTreeItem from '../../../components/StyledTreeItem';
// import { alertTitleClasses } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {}
}));


const formName = 'Dashboad';
const modelName = 'dashboad';
const label = 'Dashboad';

let Form = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();
  
  const [loadingGrupos, setLoadingGrupos] = useState(false);
  const [gruposList, setGruposList] = useState([]);  

  const [userGruposList, setUserGruposList] = useState([]); 


  const [loadingLideranca, setLoadingLideranca] = useState(false);
  const [liderancaList, setLiderancaList] = useState([]);

  const [loadingMultiplicador, setLoadingMultiplicador] = useState(false);
  const [multiplicadorList, setMultiplicadorList] = useState([]);  
  const [multiplicadorFilterList, setMultiplicadorFilterList] = useState([]);  

  // const handleClick = (e) => {
  //   AuthState.getUserInfo().then( (luser) => {
  //     history.push(`/users/${luser.id}`);
  //   })    
  // }  

  // const renderSelection = (list) => {
  //   if (list && list.length > 0) {
  //       let finalList = [<option key={'none'}>TODOS</option>];
  //       list.forEach((entity) => {
  //           finalList.push(<option name={entity.user.ownerId} value={entity.userId + '|' + entity.user.ownerId}>{entity.user.name}</option>);
  //       })
  //       return (finalList);
  //   }
  // }

  const getLiderancaList = () => {
    if (!loadingLideranca) {
        setLoadingLideranca(true);
        api.get('/users/liderancas?pageSize=100').then(list => {              
            setLiderancaList(list.data.rows);
        });
    }
  } 
  
  const getGruposList = () => {

    // api.get('/escalasAtividades/user/:id?pageSize=100').then(list => {              
    if (!loadingGrupos) {
        setLoadingGrupos(true);
        api.get('/escalasAtividades/?pageSize=100').then(list => {              
            setGruposList(list.data.rows);            
        });
    }
  }   

  const getMultiplicadorList = () => {
    if (!loadingMultiplicador) {
        setLoadingMultiplicador(true);
        api.get('/users/multiplicadores?pageSize=100').then(list => {         
            setMultiplicadorList(list.data.rows);
            setMultiplicadorFilterList(list.data.rows);
        });
    }
  } 
  
  const selectColor = (cargo) =>{
    switch (cargo) {
      case 'A':
          return '#65b4ff'
      break;
      case 'V':
        return '#70d4e6'
      break;
      case 'E':
        return '#f78585'
      break;
      case 'F':
        return '#9a6af5'
      break; 
      case 'S':
        return '#9b499b'
      break; 
      case 'P':
        return '#237c57'
      break; 
      case 'G':
        return '#e4e27c'
      break;                                                                                                                                                                 
      default:
        return '#b6b6b6'
    }      
  }

  const showLiderancas = () => {    
    let finalList = [];

    if ((liderancaList) && (liderancaList.length > 0) && (userGruposList.length === 0)) {

      liderancaList.forEach((lideranca) => {
          finalList.push(<StyledTreeItem
                            key={lideranca.userId}
                            nodeId={lideranca.userId} 
                            PhotoId={lideranca.user.cpf} 
                            labelText={lideranca.user.name}
                            labelInfo={'(10)'}
                            bgColor={selectColor(lideranca.user.cargo)}
                            DetailText={mphone(lideranca.user.phone)}
                            children={showMultiplicadores(lideranca.userId)}
                            
                          />);      
      });

    }
    
    return (finalList);
  }

  // const renderFilterMult = (e) => {    
  //   let val = e.target.value.split('|');    
  //   if (val[0] && (val[0] !== 'TODOS' )) {
  //     setMultiplicadorFilterList(multiplicadorList.filter((row)=> row.user.ownerId === val[0]));
  //   } else {
  //     setMultiplicadorFilterList(multiplicadorList);
  //   }

  // }  

  const showMultiplicadores = (masterId) => {    
    
    let finalList = [];
    let multiplicadores = multiplicadorList.filter((row)=> row.user.ownerId === masterId);

    if ((multiplicadores) && (multiplicadores.length > 0)) {

      multiplicadores.forEach((multiplicador) => {        
       finalList.push(<StyledTreeItem 
                        key={multiplicador.userId}
                        nodeId={multiplicador.userId} 
                        PhotoId={multiplicador.user.cpf} 
                        labelText={multiplicador.user.name} 
                        labelInfo={'(5)'}
                        bgColor={'#f79e76'}
                        DetailText={mphone(multiplicador.user.phone)}
                        />);
      });
    }
    return (finalList);

  }  

  // const renderSelectionGrupos = (list) => {
  //   if (list && list.length > 0) {
  //       let finalList = [<option key={'none'}>TODOS</option>];
  //       list.forEach((entity) => {
  //           finalList.push(<option name={entity.id} value={entity.id}>{entity.descricao}</option>);
  //       })
  //       return (finalList);
  //   }
  // }  

  // const changeFilterLideracas = (e) => {
    
  //   if (e.target.value === 'TODOS') {
  //     setUserGruposList([]);
  //   } else {
  //     setUserGruposList(gruposList.filter((row)=> row.id === e.target.value));
  //   }
    
  //   showLiderancas();


  //   // gruposList.filter((row)=> row.user.ownerId === e.target.value);
    
  //   // console.log(gruposList.filter((row)=> row.id === e.target.value))

    
  // }

  getLiderancaList();
  getMultiplicadorList();
  getGruposList();

  return (

    <BasicForm 
    hiddenNewButton={true} 
    hiddenSaveButton={true}
    name={'Dashboard'}
    // customAfterLoadEntity={afterLoadEntity}
    routeAfterSubmit={`/home`} 
      content={        
                <Grid container spacing={3} wrap="wrap">

                  {/* <Grid item xs={12}>
                    <Field name="grupoId" component={renderSelectField} label="Grupos" onChange={changeFilterLideracas} style={{ width: 300 }}>
                        {renderSelectionGrupos(gruposList)}
                    </Field>
                  </Grid>                    */}

                  {/* <Grid item xs={12}>
                    <Field name="liderancaId" component={renderSelectField} label="Lideranças" onChange={renderFilterMult} style={{ width: 300 }}>
                        {renderSelection(liderancaList)}
                    </Field>
                  </Grid> 

                  <Grid item xs={isMobile?12:6}>
                    <Field name="multiplicadorId" component={renderSelectField} label="Multiplicador" style={{ width: 300 }}>
                        {renderSelection(multiplicadorFilterList)}
                    </Field>
                  </Grid>  */}
                  <Grid item xs={12}  >
                    <CardHeader title="Grafo de Lideranças"/>
                  </Grid>

                  <Grid item xs={12}  >
                    <TreeView
                      aria-label="gmail"
                      defaultExpanded={['3']}
                      defaultCollapseIcon={<ExpandLess />}
                      defaultExpandIcon={<ExpandMore />}                    
                      defaultEndIcon={<div style={{ width: 24 }} />}
                      sx={{ height: '100%', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
                      
                    >
                      {showLiderancas()}
                    
                    </TreeView>

                  </Grid>   
                                  


                    
                </Grid>
              }
      formName={formName}
      modelName={modelName}
      label={label}
      { ...props }
    />
    );
};

// Form.propTypes = {
//   className: PropTypes.string
// };

Form = reduxForm({
  form: formName,
  // validate,
  // initialValues: {
  //     'status':'A',
  //     'birthDate': moment().format('YYYY-MM-DD'),
  //     'valpassword':  '000000000',
  //     'confpassword': '000000000',}
})(Form);

export default Form;
