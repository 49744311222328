import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import CardList from '../../../components/CardList/CardList';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const SendpushList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    const columns = [
        // { name: 'id', title: '#' },        
        { name: 'title', title: 'Titulo' , filterable: true, primary: true },
        { name: 'message', title: 'Mensagem', width: 250, filterable: true, secondary: true },                
    ]    


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="SendPush" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"sendpush"} endpoint={"sendpush"} columns={columns} pkid={'id'} />:
                            
                            <GridList modelName={"SendPush"} 
                                editModelRoute={'/sendpush'}
                                newModelRoute={'/sendpush/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'sendpush'}
                                enableActions={true}
                                pkid={'id'}
                                columns={[
                                { name: 'title', title: 'Titulo', width:200 },
                                { name: 'message', title: 'Mensagem', width:350 },
                            ]}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

