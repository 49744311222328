import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import {GridList} from '../../../components/GridList/GridList';
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        maxWidth: "100%",
        padding: theme.spacing(4),
        width: '100%',
        marginLeft: '1%'
    }
  }));
  
export const VagasEmpregosList = (props) => {
    const classes = useStyles();
    const { id } = useParams();

    const deleteCallback = (reponse) => {
        console.log(reponse);
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid container justify="flex-start" spacing={2} >
                <Grid item lg={12} xs={12}>
                    <Card className='cardPersonalData'>
                    <CardContent>
                        <Grid container spacing={6} wrap="wrap">
                            <GridList 
                                modelName={"VagasEmpregos"} 
                                disableNewButtom={true}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={`vagasEmpregos/${id}/empregos`}
                                enableActions={true}
                                // hiddenUpdateButton={true}
                                hiddenBackButton={true}
                                tableWidth={700}
                                
                                tableHeight={400}
                                columns={[                                
                                 { name: 'titulo', title: 'Título' },
                                 { name: 'descricao', title: 'Descrição'},
                                // { name: 'parentescoparente', title: 'Parentesco'}
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

