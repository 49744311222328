import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import {GridList} from '../../../components/GridList/GridList';
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        // margin: "auto",
        maxWidth: "100%",
        // padding: theme.spacing(4),
        width: '100%',
        marginLeft: '1%'
    }
  }));
  
export const UsersList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid container justify="flex-start" spacing={12} >
                <Grid item lg={12} xs={12}>
                    <Card className='cardPersonalData'>
                    <CardContent>
                        <Grid container spacing={12} wrap="wrap">
                            <GridList 
                                modelName={"Grupo Político"} 
                                disableNewButtom={true}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={`escalasAtividades/${props.escalaId}/users`}
                                enableActions={true}
                                hiddenUpdateButton={true}
                                tableWidth={800}
                                // style={{marginLeft:100}}
                                tableHeight={400}
                                columns={[                                
                                { name: '$user.name$', title: 'Nome', getCellValue: row => row.user ? row.user.name : null, width: 200 },                                                                
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

