import axios from 'axios';
import { ToastMessage } from '../layouts';

const API_URL_CEP = process.env.API_URL_CEP || "https://viacep.com.br/";

const apiCEP = axios.create({
    baseURL: API_URL_CEP
  });

  apiCEP.interceptors.response.use(async response => {
    return response;
  }, async error => {
    if (400 === error.response.status) {
      const msg = error.response.data.error;
      ToastMessage.error(`Problemas no servidor: ${msg}`)
    } else if (401 === error.response.status) {
      ToastMessage.warning('Sessão Inválida');
    } else if (403 === error.response.status) {
      ToastMessage.warning('Você não tem permissão para acessar esse recurso');
    } else if (500 === error.response.status) {
      ToastMessage.error('Erro interno no servidor')
    }
    return Promise.reject(error);
  }
  );

  export default apiCEP;