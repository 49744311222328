import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const EscalasAtividadesList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {        
        ToastsStore.success('Escala do usuário excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Grupo Político" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            <GridList modelName={"Grupo Político"} 
                                editModelRoute={'/escalasAtividades'}
                                newModelRoute={'/escalasAtividades/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'escalasAtividades'}
                                enableActions={true}
                                columns={[                                
                                { name: 'descricao', title: 'Descrição' , width: 200 },  
                                { name: '$secretaria.nome$', title: 'Local de Votação', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 300 , hidden:isMobile},
                             
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

