import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,  
  Divider,
  Grid,
} from '@material-ui/core';
import AuthState from '../../../../auth/Auth';
import { useHistory } from 'react-router';
import RouteButton from '../../../../components/RouteButton';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    AuthState.getUserInfo().then( (luser) => {
      history.push(`/users/${luser.id}`);
    })    
  }  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="Perfil do Usuário"
        />

        <Divider />

        <Grid container spacing={3} >

            <RouteButton 
              title={'Alterar'} 
              iconName={'person'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handleClick}
              />            

        </Grid>

        <Divider style={{marginTop:30}} />     
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
