import React, {useState, useEffect} from 'react';
import { Field, reduxForm, change } from 'redux-form';
import validCpf from '../../../../services/validCpf';
import { Grid, Divider, CardContent, CardHeader, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { renderTextField, renderSelectField, renderDatePicker, renderCheckbox, renderSwitch } from '../../../../components/Fields/Fields';
import BasicForm from '../../../../components/BasicForm';
import { cpfMask, phoneMask, cepMask } from '../../../../services/masks';
import { isMobile } from 'react-device-detect';
import InputLabel from '@material-ui/core/InputLabel';
import MyDivider from '../../../../components/MyDivider';
import moment from 'moment';
import apiCEP from '../../../../services/apiCEP';
import { useHistory } from 'react-router';


import './required-field.css';

const formName = 'PublicUser';
const modelName = 'public/usuario';
const label = 'Registro de Usuário';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'nome',
        'cpf',
        'email',
        'birthDate',
        'phone',
        'ownerId',
        'neighborhood',
        'city'
    ]


    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    // VALIDAÇAO EMAIL
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors['email'] = 'Endereço de Email invalido'
    }
    
    // VALIDAÇAO CPF
    if (values.cpf && !validCpf(values.cpf)) {
        errors['cpf'] = 'Cpf inválido';
    }

    return errors
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        // maxWidth: "100%",
        padding: theme.spacing(4),
        width: '100%',
        marginLeft: '10%',
        backgroundColor:'#1515abff',
    },
    content: {
        paddingTop: 150,
        textAlign: 'center',
    },

}));

  
let Form = (props) => {

    const classes = useStyles();
    const upper = value => value && value.toUpperCase()
    const history = useHistory();
    const [entity, setEntity] = useState({});
    const [formCompleto, setFormCompleto] = useState(false);

    const getDataByCEP = (event) => {

        let cep = event.target.value;

        apiCEP.get(`/ws/${cep}/json`).then(
            (data) => {
                props.dispatch(change(formName, 'address', data.data.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'neighborhood', data.data.bairro.toUpperCase()));
                props.dispatch(change(formName, 'city', data.data.localidade.toUpperCase()));
                props.dispatch(change(formName, 'state', data.data.uf.toUpperCase()));
                props.dispatch(change(formName, 'complement', data.data.complemento.toUpperCase()));

            }
        ).catch(err => {
        });       
        
    };  
    
    const afterLoadEntity = (entity) => {     
        setEntity(entity);

    }      

    const clickFormCompleto = (e) => {     
        setFormCompleto(e.target.checked);

    }     

    const setOwnerId = (entity) => {             
        props.dispatch(change(formName, 'ownerId', history.location.pathname.split('/')[1]));        
        props.dispatch(change(formName, 'confirmed', false));        

    }      

    return (
        <div className={classes.root}>
            <Grid container justify="center" spacing={12} >
                <Grid item lg={12} xs={12}>
                    <Grid>
                        <BasicForm
                            routeAfterSubmit="/minhahabitacao/sucesso"
                            needRecaptch={true}
                            noLoadData={true}
                            // customAfterLoadEntity={afterLoadEntity}
                            content={                                
                                <Grid container wrap="wrap" className="row">
                                    <Card className="personalData">
                                        <CardHeader title="Dados do Titular"/>
                                        <CardContent>
                                            <Grid container spacing={2} wrap="wrap">
                                                
                                                <Grid item xs={12} >
                                                    <Field name="cpf" type="text" component={renderTextField} label="CPF" normalize={upper} style={{ width: 300 }} maxLength={11} {...{required: true }} {...cpfMask()} />
                                                </Grid>
                                                {setOwnerId()}

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="name" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: 350 }} {...{ required: true }} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="phone" type="text" component={renderTextField} label="Telefone" normalize={upper} style={{ maxWidth: '100%', width: 350 }} maxLength={9} {...phoneMask} {...{ required: true }} />
                                                </Grid>  

                                                <Grid item xs={isMobile?12:6}>
                                                <InputLabel>Data de Nasc. * </InputLabel>                                                    
                                                    <Field name="birthDate" type="date" component={renderDatePicker} style={{ maxWidth: '100%', width: 350 }} {...{ required: true }} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field  id="statusProfissional" name="statusProfissional" component={renderSelectField} label="Situação Profissional" style={{ maxWidth: '100%', width: 350 }}>
                                                        <option value={'NENHUMA'}>NENHUMA</option>
                                                        <option value={'PROCURANDO'}>PROCURANDO EMPREGO</option>
                                                        <option value={'EMPREGADO'}>EMPREGADO</option>                            
                                                    </Field>
                                                </Grid>   

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="profissao" type="text" label="Profissão" component={renderTextField} normalize={upper} style={{maxWidth: '100%', width: 350}} >
                                                    </Field>
                                                </Grid>                                                   

                                                <Grid item xs={12}>
                                                    <MyDivider/>  
                                                    Endereço                    
                                                </Grid> 


                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="neighborhood" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{ maxWidth: '100%', width: 350 }} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="city" type="text" component={renderTextField} label="Cidade" normalize={upper} style={{ maxWidth: '100%', width: 350 }} {...{ required: true }}/>
                                                </Grid>                                                

                                                {/* <Grid item xs={12}>
                                                    <Field name="cep" type="text" onBlur={getDataByCEP} component={renderTextField} label="CEP" normalize={upper} style={{ maxWidth: '100%', width: 200 }} maxLength={8} {...cepMask} />
                                                </Grid>                

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="address" type="text" component={renderTextField} label="Endereço" normalize={upper} style={{ maxWidth: '100%', width: 350}} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="number" type="number" component={renderTextField} label="Número" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="neighborhood" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="city" type="text" component={renderTextField} label="Cidade" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid> */}

                                                <Grid item xs={isMobile?12:6}>
                                                    <InputLabel>Estado</InputLabel>
                                                    <Field id="state" name="state" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }}>
                                                        <option ></option>
                                                        <option value={'AC'}>Acre</option>
                                                        <option value={'AL'}>Alagoas</option>
                                                        <option value={'AP'}>Amapá</option>
                                                        <option value={'AM'}>Amazonas</option>
                                                        <option value={'BA'}>Bahia</option>
                                                        <option value={'CE'}>Ceará</option>
                                                        <option value={'DF'}>Distrito Federal</option>
                                                        <option value={'ES'}>Espírito Santo</option>
                                                        <option value={'GO'}>Goiás</option>
                                                        <option value={'MA'}>Maranhão</option>
                                                        <option value={'MT'}>Mato Grosso</option>
                                                        <option value={'MS'}>Mato Grosso do Sul</option>
                                                        <option value={'MG'}>Minas Gerais</option>
                                                        <option value={'PA'}>Pará</option>
                                                        <option value={'PB'}>Paraíba</option>
                                                        <option value={'PR'}>Paraná</option>
                                                        <option value={'PE'}>Pernambuco</option>
                                                        <option value={'PI'}>Piauí</option>
                                                        <option value={'RJ'}>Rio de Janeiro</option>
                                                        <option value={'RN'}>Rio Grande do Norte</option>
                                                        <option value={'RS'}>Rio Grande do Sul</option>
                                                        <option value={'RO'}>Rondônia</option>
                                                        <option value={'RR'}>Roraima</option>
                                                        <option value={'SC'}>Santa Catarina</option>
                                                        <option value={'SP'}>São Paulo</option>
                                                        <option value={'SE'}>Sergipe</option>
                                                        <option value={'TO'}>Tocantins</option>
                                                    </Field>
                                                </Grid>

                                                {/* <Grid item xs={isMobile?12:6}>
                                                    <Field name="complement" type="text" component={renderTextField} label="Complemento" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid> */}

                                                <Grid item xs={12}>                        
                                                    <MyDivider/>                                                      
                                                </Grid>    
                                                
                                                <Grid item xs={12}>
                                                    <Field checked={formCompleto} type="text" component={renderSwitch} onChange={clickFormCompleto} label="Mostrar Formulário Completo (Opcional)" style={{ maxWidth: '100%', width: 100}} />
                                                </Grid>   

                                                { formCompleto &&
                                                <>
                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="socialname" type="text" component={renderTextField} label="Nome Social" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>
                                                                                  

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="wifename" type="text" component={renderTextField} label="Nome Cônjuge" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>   
                                                

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="rg" type="text" component={renderTextField} label="Doc." normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>   

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field label="Email" name="email" component={renderTextField} style={{maxWidth: '100%', width: 350}}/>
                                                </Grid>                                                                                             

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field  label="Partido" name="secretaria" component={renderSelectField}  style={{maxWidth: '100%', width: 350}} >
                                                        <option value={'Nenhuma'}>Nenhuma</option>
                                                        <option value={'MDB'}>MDB</option>
                                                        <option value={'PT'}>PT</option>
                                                        <option value={'PSDB'}>PSDB</option>
                                                        <option value={'PP'}>PP</option>
                                                        <option value={'PDT'}>PDT</option>
                                                        <option value={'UNIAO BR'}>UNIAO BR</option>
                                                        <option value={'PTB'}>PTB</option>
                                                        <option value={'PL'}>PL</option>
                                                        <option value={'PSB'}>PSB</option>
                                                        <option value={'Republicanos'}>Republicanos</option>
                                                        <option value={'Cidadania'}>Cidadania</option>
                                                        <option value={'PSC'}>PSC</option>
                                                        <option value={'PCdoB'}>PCdoB</option>
                                                        <option value={'PODE'}>PODE</option>
                                                        <option value={'PSD'}>PSD</option>
                                                        <option value={'PV'}>PV</option>
                                                        <option value={'Patriota'}>Patriota</option>
                                                        <option value={'Solidariedade'}>Solidariedade</option>
                                                        <option value={'PSOL'}>PSOL</option>
                                                        <option value={'Avante'}>Avante</option>
                                                        <option value={'PMN'}>PMN</option>
                                                        <option value={'Agir'}>Agir</option>
                                                        <option value={'DC'}>DC</option>
                                                        <option value={'PRTB'}>PRTB</option>
                                                        <option value={'PROS'}>PROS</option>
                                                        <option value={'PMB'}>PMB</option>
                                                        <option value={'REDE'}>REDE</option>
                                                        <option value={'NOVO'}>NOVO</option>
                                                        <option value={'PSTU'}>PSTU</option>
                                                        <option value={'PCB'}>PCB</option>
                                                        <option value={'PCO'}>PCO</option>
                                                        <option value={'UP'}>UP</option>
                                                    </Field>
                                                </Grid>                                                 

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="site" type="text" component={renderTextField} label="Site" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="whatsapp" type="text" component={renderTextField} label="Whatsapp" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="facebook" type="text" component={renderTextField} label="Facebook" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>      

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="instagram" type="text" component={renderTextField} label="Instagram" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="twitter" type="text" component={renderTextField} label="Twitter" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>                                        

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field name="telegram" type="text" component={renderTextField} label="Telegram" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                                                </Grid>   
                                                </>
                                                }
                                                {/* <Grid item xs={12}>                        
                                                    <MyDivider/>                  
                                                </Grid>   */}


                                                {/* <Grid item xs={isMobile?12:6}>
                                                    <Field  disabled={true} id="cargo" name="cargo" component={renderSelectField} label="Cargo" style={{ maxWidth: '100%', width: 350 }}>
                                                        <option value={'A'}>ASSESSOR</option>
                                                        <option value={'V'}>VEREADOR</option>
                                                        <option value={'E'}>DEP. ESTADUAL</option>
                                                        <option value={'F'}>DEP. FEDERAL</option>
                                                        <option value={'S'}>SENADOR</option>
                                                        <option value={'O'}>OUTROS</option>
                                                        <option value={'P'}>PRE-CADASTRO</option>
                                                    </Field>
                                                </Grid>                                                 

                                                <Grid item xs={isMobile?12:6}>
                                                    <Field disabled={true} name="ownerId" component={renderTextField} label="Indicado Por" style={{ maxWidth: '100%', width: 350 }} {...{required: true }}  />
                                                </Grid>                                                                                                 */}

                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Divider />
                                    
   


                                </Grid>
                            }
                            formName={formName}
                            modelName={modelName}
                            label={label}
                            { ...props }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{'status':'A',
                   'cargo' :'PC',
                   'birthDate': moment().format('YYYY-MM-DD'), 
                   'state': 'PB',
                   
                }
})(Form);

export default Form;

