export { default as Success } from './candidatos/public/Success';
export { default as ResetPasswordSuccess } from './candidatos/public/ResetPasswordSuccess';
export { default as NotFound } from './NotFound';
export { default as Forbidden } from './Forbidden';
export { default as Settings } from './Settings';
export { default as FormCompany } from './companies';
export { default as FormUser } from './users';
export { default as FormProfile } from './profiles';
export { default as FormGrant } from './grants';
export { default as FormUserCompanyProfile } from './userCompanyProfiles';
export { default as FormCandidato } from './candidatos';
export { default as FormConjuge } from './candidatos/conjuge';
export { default as FormEndereco } from './candidatos/endereco';
export { default as FormFamilia } from './candidatos/familia';
export { default as FormDemografico } from './candidatos/demografico';
export { default as FormDocumento } from './candidatos/documentos';
export { default as FormExame } from './exames';
export { default as FormResultado } from './resultados';
export { default as FormConcorrencia } from './concorrencias';
export { default as FormInscricoes } from './inscricoes';
export { default as FormSecretaria } from './secretarias';
export { default as FormAssunto } from './assuntos';
export { default as FormSla } from './slas';
export { default as FormDemanda } from './demandas';
export { default as FormDemandaSimples } from './demandaSimples';
export { default as PublicFormCandidato } from './candidatos/public';
export { default as PublicFormRegUsers } from './users/public';
export { default as PublicFormCracha } from './users/public/cracha';

export { default as FormRegistroAtividade } from './registroAtividades';
export { default as FormEscalasAtividades } from './escalasAtividades';
export { default as FormEscalasAtividadesIndividual } from './escalasAtividadesIndividual';
export { default as FormRegistroAtividadeCfg } from './registroAtividadesCfg';
export { default as FormRegistroAtividadeIndividual } from './registroAtividadesIndividual';
export { default as FormRegistroAtividadesHistorico } from './registroAtividadesHistorico';
export { default as FormGeolocalizacao } from './geolocalizacao';
export { default as FormUserLideranca } from './usersLiderancas';
export { default as FormUserMultiplicador } from './usersMultiplicadores';
export { default as FormSendpush } from './sendPush';
export { default as FormProcedimentosMedicos} from './procedimentosMedicos';

export { default as FormResume } from './resumes';
export { default as FormEventReport } from './eventReports';
export { default as FormVagasEmprego } from './vagasEmprego';

export { default as Dashboad } from './dashboard';
