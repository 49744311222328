import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import AuthState from '../../auth/Auth';

import { Password, Candidato, SadRegistro, Cracha, Admin, Liderancas } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = () => {
  const classes = useStyles();

  const profile = AuthState.getProfileInfo();
 

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        wrap="wrap"
      >

{/* 
        {((profile.name === "ADMIN") || (profile.name === "CANDIDATO")) &&
        <Grid item xs={12} md={8} >
          <SadRegistro />
        </Grid>} */}

        {/* <Grid item xs={12} md={8} >
          <Photo />
        </Grid> */}

        <Grid item  xs={12} md={8}>
          <Password />
        </Grid>

        {/* {(profile.name === "ADMIN") &&
        <Grid item  xs={12} md={8}>
          <Admin />
        </Grid> }    */}

        {((profile.name === "ADMIN") || 
          (profile.name === "MULTIPLICADOR") ||
          (profile.name === "LIDERANCA")) &&
        <Grid item  xs={12} md={8}>
          <Cracha profile={profile.name} />
        </Grid> }   

        {((profile.name === "ADMIN") || 
          (profile.name === "LIDERANCA")) &&
        <Grid item  xs={12} md={8}>
          <Liderancas profile={profile.name} />
        </Grid> }               

       

      </Grid>
    </div>
  );
};

export default Settings;
