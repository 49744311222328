import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/styles';
import {  
  Button,
  Grid,
  Typography 
} from '@material-ui/core';

import Icon from '../icon/icons';

// const useStyles = makeStyles(() => ({
//     root: {}
//   }));
  
const RouteButton = props => {    
    const title         = props.title;
    const iconName      = props.iconName;
    const iconSize      = props.iconSize;
    const bgColor       = props.bgColor;
    const handlerAction = props.handlerAction;
    const fill          = props.fill;

    const handlerClick = (e) => {        
        handlerAction(e); 
      }       
    
 
    return (
            <Grid direction="column" justifyContent="center" style={{paddingLeft:5}} >
                
            
                <Button
                color="primary"
                variant="outlined"
                style={{ maxWidth: '100%', width: 120, margin:15, height:70, backgroundColor: bgColor}}
                onClick={handlerClick}
                >                
                <Icon icon={iconName} size={iconSize} fill={fill}/>     

                </Button>
                <Typography align="center" variant="h4" onClick={handlerClick} >
                {title}
                </Typography> 
            
            </Grid>  
            )
    }

RouteButton.propTypes = {
    title    : PropTypes.string,
    iconName : PropTypes.string,
    iconSize : PropTypes.number,
    bgColor  : PropTypes.string,
    fill     : PropTypes.string,
    handlerAction: PropTypes.func
};

export default RouteButton;            