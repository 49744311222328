import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,  
  Divider,
  Grid,
} from '@material-ui/core';
import AuthState from '../../../../auth/Auth';
import { useHistory } from 'react-router';
import LOCAL_URL from '../../../../services/qrCodeConfig';

import { ToastMessage } from '../../../../layouts';

import RouteButton from '../../../../components/RouteButton';

//import { getToken, deleteToken } from "../../../../services/firebaseNotification/index";

// import LOCAL_URL from '../../../../services/qrCodeConfig'

const useStyles = makeStyles(() => ({
  root: {}
}));

const Cracha = props => {
  const { className, ...rest } = props;
  const profile = props.profile;

  const classes = useStyles();
  const history = useHistory();

  const handleDashboad = (e) => {
    AuthState.getUserInfo().then( (luser) => {
        history.push(`/dashboad`);
    })    
  }

  const handleClick = (e) => {
    AuthState.getUserInfo().then( (luser) => {
        history.push(`/users/new`);
    })    
  }
  
  const handleClickListUsers = (e) => {
    AuthState.getUserInfo().then( (luser) => {
        history.push(`/users`);
    })    
  }

  const handleRegistrar = (e) => {
    history.push(`/escalasAtividadesIndividual`);
  }  
  
  const handleGruposPoliticos = (e) => {
    history.push(`/escalasAtividades`);
  }   
  
  const handleHistorico = (e) => {
    history.push(`/geolocalizacao`);
  } 

  const handleEvento = (e) => {
    history.push(`/registroAtividadesCfg`);
  } 

  const handleSendClipBoard = (e) => {
      AuthState.getUserInfo().then( (luser) => {
        navigator.clipboard.writeText(`${LOCAL_URL}/${luser.id}/regusuario/`)
        ToastMessage.success('Link Copiado com Sucesso');
    }) 
     
  } 

  const handleShare =  (e) => {

    try {
    AuthState.getUserInfo().then( async (luser) => {

      const shareData = {
        title: 'Fator Político',
        text: 'Faça seu cadastro no Fator Político',
        url: `${LOCAL_URL}/${luser.id}/regusuario/`
      } 
      await navigator.share(shareData);
      
    }) 
    } catch(err) {
      alert('Não suportado')

    }

  }   

  const handlePush = (e) => {
    history.push(`/sendpush`);
  } 

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title={"Menu do " + profile}
          // subheader="Exibir Cracha"
          
        />
        
        <Grid container spacing={3} wrap="wrap">

            <RouteButton 
              title={'Dashboard'} 
              iconName={'dash'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handleDashboad}
              />  

            <RouteButton 
              title={'(+) Contato'} 
              iconName={'person_add_alt_1'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handleClick}
              />                 

            <RouteButton 
              title={'Contatos'} 
              iconName={'person'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handleClickListUsers}
              />  

            {profile && profile !== 'MULTIPLICADOR' &&  
              <RouteButton 
              title={'Grupos'} 
              iconName={'Grupos'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handleGruposPoliticos}
              />
            }        

            <RouteButton 
              title={'Meus Grupos'} 
              iconName={'Grupos'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handleRegistrar}
              />                       

            {profile && profile !== 'MULTIPLICADOR' &&   
            <RouteButton 
              title={'Eventos'} 
              iconName={'payment'} 
              iconSize={36} 
              bgColor={'#3332e4'}
              fill={'#fff'} 
              handlerAction={handleEvento}
              />                
            }    
            
            {((profile) && (profile !== 'MULTIPLICADOR')) &&    
            
            <RouteButton 
              title={'Geo'} 
              iconName={'map'} 
              iconSize={36} 
              bgColor={'#3332e4'}
              fill={'#fff'} 
              handlerAction={handleHistorico}
              /> 
            }

            {((profile) && (profile !== 'MULTIPLICADOR')) && 
            
            <RouteButton 
              title={'Link'} 
              iconName={'link'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handleSendClipBoard}
              />            
            }   

            {((false) && (profile) && (profile !== 'MULTIPLICADOR')) &&     
            
            <RouteButton 
              title={'Compartilhar'} 
              iconName={'share'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
               handlerAction={handleShare}
              />   
                
            }  

            {((profile) && (profile !== 'MULTIPLICADOR')) &&     
            <RouteButton 
              title={'SendPush'} 
              iconName={'podcast'} 
              iconSize={36} 
              fill={'#fff'}
              bgColor={'#3332e4'} 
              handlerAction={handlePush}
              />   
                
            }             

        </Grid>
          
        <Divider style={{marginTop:50}} />          

      </form>
    </Card>
  );
};

Cracha.propTypes = {
  className: PropTypes.string
};

export default Cracha;
