import React from 'react';
import './styles/descricao.css'
import logomersinho from './assets/Logo-conecta-mersinho-ícone-gradiente 5.png'
import fotoPerfilAzul1 from './assets/Foto-Azul-PNG6.webp'
import fotoPerfilAzul from './assets/Foto-Azul-PNG8.webp'
import fotoPerfilAzul2 from './assets/Foto-Azul-PNG9.webp'
import { border } from '@mui/system';

function Descricao  () {


    return ( <section id="descricao">


        <div id="foto-logo">
            <img src={logomersinho} alt="logomersinho" style={{marginTop:0, backgroundColor:"#FFF"}}/>
        </div>

        <div id="main-descricao">


        <picture className="foto-descricao">
                <source media="(max-width: 450px)" srcset={fotoPerfilAzul2} type="image/png"/>
                <source media="(max-width: 750px)" srcset={fotoPerfilAzul} type="image/png"/>
                <img src={fotoPerfilAzul1} alt="fotomersinho"/>
        </picture>


            <div id = "txt-descricao">
                <h4 id='titulo-descricao'>Biografia</h4>
                <p id='paragrafo-descricao' style={{fontStyle:border}}>
                    Mersinho, paraibano, iniciou sua carreira acadêmica em Engenharia Civil na UFPB e Administração na Unipê. 
                    Apesar de não ter concluído os cursos, sua paixão pela política o levou a ser vice-prefeito de Cabedelo - PB em 2020, 
                    onde se destacou até 2022. Atualmente, é Deputado Federal com mandato até 2026, com foco em um Brasil mais competitivo, 
                    equitativo e empreendedor, buscando reduzir as disparidades sociais
                </p>                

                <p id='paragrafo-descricao' style={{marginTop:30}}>
                Ele também defende melhorias na saúde pública, infraestrutura e Direitos Humanos, valorizando o diálogo e integridade na política. 
                Com uma expressiva votação de 114.818 votos, Mersinho promete um futuro promissor sob sua liderança visionária.
                </p>
            </div>



        </div>



    </section>
    );
};

export default Descricao;
