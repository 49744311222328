import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import { isMobile } from 'react-device-detect';
import CardList from '../../../components/CardList/CardList';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));

  const columns = [
    // { name: 'id', title: '#' },        
    // { name: '$users.name$', title: 'Nome', getCellValue: row => row.users ? row.users.name : null , filterable: true, primary: true },
    { name: 'titulo', title: 'Descrição', width: 150, filterable: true, secondary: true },        
    // { name: '$beneficiarios.name$', title: 'Beneficiário',getCellValue: row => row.beneficiarios ? 'R$ ' + row.beneficiarios.name : null, width:150 },
    { name: 'descricao', title: 'Valor', width: 100, filterable: false, content: true }, 
    // { name: '$users.cpf$', title: 'CPF', width: 1 , filterable: false, avatar: true },           
]    
  
export const VagasEmpregosList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Vaga de emprego excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Vagas Empregos" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                        {/* <CardList route={"registroAtividadesCfg"} endpoint={"registroAtividadesCfg"} columns={columns} pkid={'userId'} />: */}
                        {isMobile ?
	                    <CardList route={"vagasEmpregos"} endpoint={"vagasEmpregos"} columns={columns} />:
                        
                        <GridList modelName={"VagasEmprego"} 
                                editModelRoute={'/vagasEmpregos'}
                                newModelRoute={'/vagasEmpregos/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'vagasEmpregos/'}
                                enableActions={true}
                                columns={[                                                                
                                { name: '$secretaria.nome$', title: 'Empresa', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 200, hidden:isMobile },
                                { name: 'titulo', title: 'Título',  width: 150},                                
                                { name: '$users.name$', title: 'Responsável', getCellValue: row => row.users ? row.users.name : null, width: 200, hidden:isMobile },
                                // { name: 'descricao', title: 'Descrição', width: 200, hidden:isMobile },
                                { name: 'status', title: 'Situação', 
                                            getCellValue: (row) => {
                                                                        switch (row.status) {
                                                                            case 'O':
                                                                                return 'Ocupado' 
                                                                            case 'D':
                                                                                return 'Disponível'
                                                                            case 'C':
                                                                                return 'Cancelado'
                                                                            default: return 'Disponível'
                                                                        }

                                                                    } ,
                                            width: 200, hidden:isMobile },
                                            { name: '$beneficiarios.name$', title: 'Beneficiário', getCellValue: row => row.beneficiarios ? row.beneficiarios.name : null, width: 300, hidden:isMobile },
                                            
                                // { name: 'tipo', title: 'Tipo', getCellValue: row => (row.tipo === 'P')  ? 'PERIÓDICO' : 'RADÔMICO', width: 150 },
                            ]}/>
                        }
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

