import { Tooltip } from '@material-ui/core';
import { Table, TableHead, TableContainer, Paper, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore, Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../icon/icons';
import FilterableColumn from './FilterableColumn';

const useStyles = makeStyles(({
    filterName: {
        marginRight: 10
    },
    submitButton: {
        width:130,
        maxWidth: "100%",
        marginTop:15
    },
}))

const Filter = ({fields, query, route, hideAddButton}) => {
    const history = useHistory();

    const [enabled, setEnabled] = useState(false)
    const [filters, setFilters] = useState({})

    const classes = useStyles();

    const handleFilterClick = (e) => {
        e.preventDefault()

        let filter = []

        Object.keys(filters).forEach((key) => {
            const field = fields.filter((f) => f.name === key)
            filter.push(`[${key},${(field[0] && field[0].filterOperation) || 'contains'},${encodeURIComponent(filters[key])}]`)
        })

        if (filter.length > 0) {
            filter = `filter=${filter.join(',and,')}`;
        }

        query && query(filter)
        setEnabled(false)
    }

    const changeFilters = (obj) => {
        let newFilters = {}
        Object.assign(newFilters, filters)
        const key = Object.keys(obj)[0]
        newFilters[key] = obj[key]

        setFilters(newFilters)
    }

    return <>
        <div className="row">
            <Button onClick={() => setEnabled(!enabled)} >
                        <div className={classes.filterName} style={{marginLeft:10, marginRight:10}} ><Icon icon='search' size={18} /></div>
                        Filtros
                {enabled ? <ExpandLess /> : <ExpandMore />}
            </Button>

            <Tooltip title="Limpar filtros">
                <Button onClick={() => {
                    setFilters({})
                    setEnabled(false)
                    query && query("")
                }}>
                    <Icon icon='clear_all' size={18}/>
                </Button>
            </Tooltip>
            
            {route && !hideAddButton && <Tooltip title={`Novo(a) ${route}`}>
                <Button onClick={() => { history.push(`${route}/new`) }}>
                    <Add size={24} />
                </Button>
            </Tooltip>}
        </div>
    
        {enabled && <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        {fields.map((f) => <FilterableColumn key={f.name} field={f} defaultValue={filters[f.name]} onChange={changeFilters}/>)}
                    </TableHead>
                </Table>
            </TableContainer>
            <Button type="submit" className={classes.submitButton} color="primary" variant="contained" onClick={handleFilterClick}> Filtrar </Button>
        </div>}
    </>
}

export default Filter;
