import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import api from '../../services/api';
import { Avatar } from '@material-ui/core';


// import TreeView from '@mui/lab/TreeView';
// import MailIcon from '@mui/icons-material/Mail';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Label from '@mui/icons-material/Label';
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
// import InfoIcon from '@mui/icons-material/Info';
// import ForumIcon from '@mui/icons-material/Forum';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';



const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {       
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },        
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = (props) => {
  const {
    bgColor,
    color,
    labelInfo,
    labelText,
    DetailText,
    PhotoId,
    children,
    ...other
  } = props;

  const [photoUrl, setPhotoUrl] = useState('');
  const [loadingPhotoUrl, setLoadingPhotoUrl] = useState(false);

  const getPhotoUrl = ()  => {
    if (!loadingPhotoUrl) {
        api.get(`/users/photourl/${PhotoId}`)
        // api.get(`/users/photourl/11111111111`)
        .then((response) => {
            setLoadingPhotoUrl(true);
            const preSignedURL = response.data.url
            setPhotoUrl(preSignedURL);

        })
        .catch((error) => {
            console.error(error);            
            // ToastMessage.error('Problema ao atualizar Perfil');
        })
    }

}  

  getPhotoUrl();

  return (
    <StyledTreeItemRoot 
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}  >
          <Box color="inherit" sx={{ mr: 1 }} style={{backgroundColor:bgColor}} >
            <Avatar
                  alt={labelText}
                  style={{margin:3, width: 30,height: 30, maxWidth: '100%',maxHeight: '100%'}}                            
                  src={ photoUrl || "/images/avatars/generic-user.png"}
            />             
          </Box>
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
            <br/>
            <h6>
            { DetailText}
            </h6>
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
 
        maxWidth: '100%',
        maxHeight: '100%'
      }}
      {...other}
    >
      {children}
    </StyledTreeItemRoot>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};



export default StyledTreeItem;