import React, {useState} from 'react';
import { Field, reduxForm, change} from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField, renderSelectField, renderCheckbox, renderTextAreaField, renderDatePicker} from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import api from '../../../services/api';
import { isMobile } from 'react-device-detect';
import { moneyMask, numberMask } from '../../../services/masks';
import { useParams } from "react-router-dom";

const formName = 'RegistroAtividadesCfg';
const modelName = 'registroAtividadesCfg';
const label = 'Eventos';

let Form = (props) => {
    // const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    const [secretariaList, setSecretariaList] = useState([]); 
    

    const [loadingResponsavel, setLoadingResponsavel] = useState(false);
    const [responsavelList, setResponsavelList] = useState([]);   

    const [loadingProcMedicos, setLoadingProcMedicos] = useState(false);
    const [procMedicosList, setProcMedicosList] = useState([]);      
    
    const { userId } = useParams();

    
    const getSecretariasList = () => {

        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/users/contato?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);                
            });
        }
    }  

    
    const getProcMedicosList = () => {

        if (!loadingProcMedicos) {
            setLoadingProcMedicos(true);
            api.get('/procMedicos?pageSize=-1').then(list => {
                setProcMedicosList(list.data.rows);                
            });
        }
    }      

    const getResponsaveisList = () => {

        if (!loadingResponsavel) {
            setLoadingResponsavel(true);
            api.get('/users/responsavel?pageSize=-1').then(list => {

                let result = [];
                // console.log(list.data.rows);
                list.data.rows.forEach((item) => {
                    result.push(item.user)
                });
                // console.log(result);
                setResponsavelList(result);                
            });
        }
    }      

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name}</option>);
            })            
            return (finalList);
        }
    } 

    const setUserOwner= () =>{
        // alert(userId);
        if ((userId) &&(loadingResponsavel)){
            props.dispatch(change(formName, 'ownerId', userId));
        }         

    }
   
    setUserOwner();
    getSecretariasList();
    getResponsaveisList();
    getProcMedicosList();

    return (
        <BasicForm
            // customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            // customAfterInitialState={afterInitialState}
            imageContext={'registroAtividadesCfg'}
            noLoadData={userId?true:false}
            maxImages={5}
            content={
                <Grid container spacing={3} wrap="wrap">
                    
                    <Grid item xs={12}>
                        <Field name="ownerId" component={renderSelectField} label="Responsável" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(responsavelList)}
                        </Field>
                    </Grid>                     

                    <Grid item xs={12}>
                        <Field name="descricao" type="text" component={renderTextField} label="Descrição" normalize={upper} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="detalhe" type="text" component={renderTextAreaField} label="Detalhamento" normalize={upper} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>                    

                    <Grid item xs={isMobile?12:6}>
                        <Field label="Tipo" name="tipo" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'F'}>Financeiro</option>
                            <option value={'M'}>Médico</option>
                            <option value={'I'}>Indicação</option>
                            <option value={'O'}>Outros</option>
                        </Field>
                    </Grid>   

                    <Grid item xs={isMobile?12:6}>
                        <Field label="Procedimento" name="procedimento" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'Exame'}>Exame</option>
                            <option value={'Consulta'}>Consulta</option>
                            <option value={'Cirurgia'}>Cirurgia</option>                            
                            <option value={'Retorno'}>Retorno</option>                            
                        </Field>
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field label="status" name="status" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'A'}>Ativo</option>
                            <option value={'I'}>Inativo</option>
                            <option value={'R'}>Removido</option>
                        </Field>
                    </Grid> 

                    <Grid item xs={isMobile?12:6}>
                        <Field name="valor" type="text" component={renderTextField} label="Valor" style={{ maxWidth: '100%', width: 350 }} {...moneyMask}/>
                    </Grid>                                         

                    <Grid item xs={isMobile?12:6}>
                        <Field name="data" type="date" component={renderDatePicker} label="Data" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="procedimentosMedicosId" component={renderSelectField} label="Proc. Médico" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(procMedicosList)}
                        </Field>
                    </Grid>                        

                    <Grid item xs={isMobile?12:6}>
                        <Field name="beneficiario" component={renderSelectField} label="Beneficiário" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                    </Grid>                     

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        'status':'A',
        'tipo':'P',
    }
})(Form);

export default Form;

