import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AuthState from '../../../../auth/Auth';

import Icon from '../../../../components/icon/icons';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 95,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    paddingStart: 15,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(15),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const pages = [
    {
      title: 'Home',
      href: '/home',
      icon: <Icon icon='workHome' />,
      parent: 'root',
    },
    {
      title: 'Configurações',
      href: '#admin',
      icon: <Icon icon='phonelinksetup' />,
      parent: '',
      children: [
        {
          title: 'Permissões',
          href: '/grants',
          icon: <Icon icon='lockOpen' />,
          parent: 'Administração',
        },
        {
          title: 'Perfis',
          href: '/profiles',
          icon: <Icon icon='contactmail' />,
          parent: 'Administração',
        },
        {
          title: 'Lista de Usuários',
          href: '/users',
          icon: <Icon icon='users' />,
          parent: 'Administração',
        },
        {
          title: 'Empresas',
          href: '/companies',
          icon: <Icon icon='condominio' />,
          parent: 'Administração',
        },
        {
          title: 'Perfis de Usuários',
          href: '/user/company/profiles',
          icon: <Icon icon='person' />,
          parent: 'Administração',
        },
        {
          title: 'Emp. Parceiras',
          href: '/secretarias',
          icon: <Icon icon='condominio' />,
          parent: 'Administração',
        },
      ],
    },
    {
      title: 'Fator Politico',
      href: '#funcionarios',
      icon: <Icon icon='workHome' />,
      parent: '',
      children: [
        {
          title: 'Cargo',
          href: '/usersLiderancas',
          icon: <Icon icon='colaboradores' />,
          parent: 'Fator Politico',
          children: [
            {
              title: 'Assessor',
              href: '/usersLiderancas/A',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Vereador',
              href: '/usersLiderancas/V',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Dep. Estadual',
              href: '/usersLiderancas/E',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            }, 
            {
              title: 'Dep. Federal',
              href: '/usersLiderancas/F',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Senador',
              href: '/usersLiderancas/S',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Prefeito',
              href: '/usersLiderancas/P',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Governador',
              href: '/usersLiderancas/G',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Outros',
              href: '/usersLiderancas/O',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Pré-Cadastro',
              href: '/usersLiderancas/PC',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
            {
              title: 'Todos',
              href: '/usersLiderancas/T',
              icon: <Icon icon='colaboradores' />,
              parent: 'Lideranças',
            },
          ]
        },
        {
          title: 'Multiplicadores',
          href: '/usersMultiplicadores',
          icon: <Icon icon='device_hub' />,
          parent: 'Fator Politico',
        },    
        {
          title: 'Contatos',
          href: '/users',
          icon: <Icon icon='person' />,
          parent: 'Fator Politico',
        },

        {
          title: 'Grupos',
          href: '/escalasAtividades',
          icon: <Icon icon='Grupos' />,
          parent: 'Fator Politico',
        },
        {
          title: 'Eventos',
          href: '/registroAtividadesCfg',
          icon: <Icon icon='payment' />,
          parent: 'Fator Politico',
        },
        {
          title: 'Relatório de Eventos',
          href: '/eventReports',
          icon: <Icon icon='payment' />,
          parent: 'Fator Politico',
        },
        {
          title: 'SendPush',
          href: '/sendpush',
          icon: <Icon icon='podcast' />,
          parent: 'Fator Politico',
        },     
        
        {
          title: 'Proc. Médicos',
          href: '/procmedicos',
          icon: <Icon icon='lab' />,
          parent: 'Fator Politico',
        },               
        

        // {
        //   title: 'Registro Geral',
        //   href: '/registroAtividades',
        //   icon: <Icon icon='playlist_add' />,
        //   parent: 'Contatos',
        // },

        // {
        //   title: 'Registro Individual',
        //   href: '/registroAtividadesIndividual',
        //   icon: <Icon icon='playlist_add' />,
        //   parent: 'Contatos',
        // },
        // {
        //   title: 'Registro Histórico',
        //   href: '/registroAtividadesHistorico',
        //   icon: <Icon icon='history' />,
        //   parent: 'Contatos',
        // },
        
        {
          title: 'Geo',
          href: '/geolocalizacao',
          icon: <Icon icon='map2' />,
          parent: 'Fator Politico',
        },
        ],
    },

    ,
    {
      title: 'Currículos',
      href: '#curriculos',
      icon: <Icon icon='profile' />,
      parent: '',
      children: [
        {
          title: 'Banco de currículos',
          href: '/resumes',
          icon: <Icon icon='person' />,
          parent: 'Currículos',
        },
        {
          title: 'Contatos',
          href: '/users',
          icon: <Icon icon='person' />,
          parent: 'Currículos',
        },
        {
          title: 'Grupos',
          href: '/escalasAtividades',
          icon: <Icon icon='Grupos' />,
          parent: 'Currículos',
        },
        {
          title: 'Empregos',
          href: '/vagasEmpregos',
          icon: <Icon icon='briefcase' />,
          parent: 'Currículos',
        },          
        {
          title: 'SendPush',
          href: '/sendpush',
          icon: <Icon icon='podcast' />,
          parent: 'Currículos',
        },
      ],
    },

    {
      title:'Sair',
      href: '#sair',
      icon:  <>
              <IconButton
                // color="inherit"
                style={{marginLeft:-10}}
                onClick={() => {
                  AuthState.logout(() => {
                    history.push("/login")
                  });
                }}>
                <Icon icon='exit' size='24'/>
              </IconButton>
            </>,
      parent: 'root',
    },
  ];

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} key={pages.title} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
