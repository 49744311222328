import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{background:'#3332e4ff'}} 
    >
      <Typography style={{color:'#ecececff'}}>
        &copy;{' '}
        <Link
          style={{color:'#ecececff'}}
          component="a"
          href="https://www.hestiacondominios.com.br/"
          target="_blank"

        >
          Hestia Sistemas Integrados
        </Link>
        . 2022        
      </Typography>

      <Typography style={{color:'#ecececff'}}>        
        Integrando você ao mundo!      
      </Typography>      
      {/* <Typography variant="caption" > */}
      
      {/* </Typography> */}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
