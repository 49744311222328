import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const DemandasList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Demanda excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Demandas" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            <GridList modelName={"Demanda"} 
                                editModelRoute={'/demandas'}
                                newModelRoute={'/demandas/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'demandas'}
                                enableActions={true}
                                columns={[
                                    { name: 'protocolo', title: 'Protocolo', width: 80 },
                                    { name: '$secretaria.nome$', title: 'Secretaria', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 130 },                                
                                    { name: 'cep', title: 'Cep' , width: 130},
                                    { name: 'situacao', title: 'situacao' , width: 130},
                                    { name: 'qualidade', title: 'Qualidade' , width: 130},
                                    { name: 'tipo', title: 'Tipo' , width: 130},
                                
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

