import React from 'react';
import { Grid } from "@material-ui/core"
import { Field } from 'redux-form';
import { renderTextField, renderTextAreaField, renderSelectField, renderDatePicker } from '../../../components/Fields/Fields';
import EstadoOptions from './EstadoOptions';
import PaisOptions from './PaisOptions';
import MyDivider from '../../../components/MyDivider';

const EducationSection = (props) => {
    const upper = value => value && value.toUpperCase()
    const idx = props.idx || 0;
    const customChange = props.customChange;

    return (
        <>
            <Grid item xs={6}>
                        <Field name={`educations.${idx}.school`} type="text" component={renderTextField} label="Escola" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>

            <Grid item xs={6}>
                <Field name={`educations.${idx}.degree`} type="text" component={renderTextField} label="Grau" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>

            <Grid item xs={3}>
                <Field name={`educations.${idx}.startDate`} type="date" label="Data de início" component={renderDatePicker} style={{maxWidth: '100%', width: 300}} {...{ required: true }} />
            </Grid>

            <Grid item xs={3}>
                <Field name={`educations.${idx}.endDate`} type="date" label="Data de conclusão" component={renderDatePicker} style={{maxWidth: '100%', width: 300}} />
            </Grid>

            <Grid item xs={6}>
                <Field name={`educations.${idx}.country`} type="text" component={renderSelectField} label="País" normalize={upper} style={{width: 330}} {... {required: true}} >
                    <PaisOptions />
                </Field>
            </Grid>

            <Grid item xs={6}>
                <Field name={`educations.${idx}.state`} type="text" component={renderSelectField} label="Estado" normalize={upper} style={{width: 330}} {... {required: true}} >
                    <EstadoOptions />
                </Field>
            </Grid>

            <Grid item xs={6}>
                <Field name={`educations.${idx}.city`} type="text" component={renderTextField} label="Cidade" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>

            <Grid item xs={12}>
                <Field name={`educations.${idx}.description`} type="text" component={renderTextAreaField} label="Descrição" normalize={upper} style={{width: '100%'}} {... {required: true}} />
            </Grid>
            
            <Grid item xs={12}>
                <MyDivider />
            </Grid>
        </>
    )
}

export default EducationSection;