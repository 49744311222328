import React ,{useState} from 'react';
import { Field, reduxForm, change, unregisterField } from 'redux-form';
import { useSelector } from 'react-redux';
import { validate } from '../../../services';
import { renderTextField, renderTextAreaField, renderSelectField, renderSwitch } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { cnpjMask, phoneMask } from '../../../services/masks';
import apiCEP from '../../../services/apiCEP';
import MyDivider from '../../../components/MyDivider';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid, Divider, CardContent, CardHeader, Card, Button } from '@material-ui/core';
import { VagasEmpregosList } from './VagasEmpregoList';
import Icon from '../../../components/icon/icons';
import AuthState from '../../../auth/Auth';
import api from '../../../services/api';
import { ToastMessage } from '../../../layouts';
import { isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const formName = 'Secretaria';
const modelName = 'secretarias';
const label = 'Empresas Parceiras';

let Form = (props) => {
    const [entity, setEntity] = useState({});
    
    const upper = value => value && value.toUpperCase()
    const [dadosEndereco, setDadosEndereco] = useState(false);
    const [dadosVagasEmpregos, setDadosVagasEmpregos] = useState(false);
    const [showVagasEmpregos, setShowVagasEmpregos] = useState(false); 
    
    const isAdmin = (AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false; 
    const profileInfo = AuthState.getProfileInfo() || false;
    const [user, setUser] = useState({});  

    const [loadingResponsavel, setLoadingResponsavel] = useState(false);
    const [responsavelList, setResponsavelList] = useState([]);    
    
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    

    const afterLoadEntity = (entity) => {
        setEntity(entity);
        setShowVagasEmpregos(true);        
        AuthState.getUserInfo().then( (luser) => setUser(luser));        
    }

    // const afterInitialState = () => {
    //     setEntity({});
    // }

    const clickDadosEndereco = (e) => {     
        setDadosEndereco(e.target.checked);
        props.dispatch(unregisterField(formName, 'dadosEndereco'));
        props.dispatch(unregisterField(formName, 'dadosVagasEmpregos'));

    }    

    const clickDadosVagasEmpregos = (e) => {     
        setDadosVagasEmpregos(e.target.checked);
        props.dispatch(unregisterField(formName, 'dadosVagasEmpregos'));

    }  
    
    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name}</option>);
            })            
            return (finalList);
        }
    } 
    
    const getResponsaveisList = () => {

        if (!loadingResponsavel) {
            setLoadingResponsavel(true);
            api.get('/users/responsavel?pageSize=-1').then(list => {


                let result = [];
                // console.log(list.data.rows);

                list.data.rows.forEach((item) => {
                    result.push(item.user)
                });
                // console.log(result);
                setResponsavelList(result);                
            });
        }
    }         

    getResponsaveisList();    

    const getDataByCEP = (event) => {

        let cep = event.target.value;

        apiCEP.get(`/ws/${cep}/json`).then(
            (data) => {
                props.dispatch(change(formName, 'address', data.data.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'neighborhood', data.data.bairro.toUpperCase()));
                props.dispatch(change(formName, 'city', data.data.localidade.toUpperCase()));
                props.dispatch(change(formName, 'state', data.data.uf.toUpperCase()));
                props.dispatch(change(formName, 'complement', data.data.complemento.toUpperCase()));

            }
        ).catch(err => {
        });
        
    };

    
    // const Lstate = useSelector(state => state);

    const addVagaEmprego = (event) => {
        event.preventDefault();

        const body = {
            titulo: empregoTitulo,
            descricao: empregoDescricao,
            necessario: empregoNecessario,        
            desejado: empregoDesejado,
            observacoes: empregoObservacoes,
            status: empregoStatus,
            companyId: AuthState.getCompanyInfoDetail().id,
            secretariaId: entity.id,
            ownerId: empregoResponsavel

        }   

        api.post(`vagasEmpregos/`, body)
        .then(response => {
            // console.log(response)
            props.dispatch(change(formName, 'empregoTitulo', ''));
            props.dispatch(change(formName, 'empregoDescricao', ''));
            props.dispatch(change(formName, 'empregoNecessario', ''));
            props.dispatch(change(formName, 'empregoDesejada', ''));
            props.dispatch(change(formName, 'empregoObservacoes', ''));
            props.dispatch(change(formName, 'empregoStatus', ''));
            props.dispatch(change(formName, 'enpregoOwnerId', ''));            

            setShowVagasEmpregos(false);
            setShowVagasEmpregos(true);
            ToastMessage.success('Membro adicionado à família');
        })
        .catch(error => {
            ToastMessage.warning('Problemas ao inserir dado familiar');
        })        

    }

    
    const [empregoTitulo, setEmpregoTitulo] = useState('');
    const changeTitulo = (e) => {
        if (e.target.value) setEmpregoTitulo(e.target.value.toUpperCase())
    }

    const [empregoDescricao, setEmpregoDescricao] = useState('');
    const changeDescricao = (e) => {
        if (e.target.value) setEmpregoDescricao(e.target.value.toUpperCase())
    }   

    const [empregoNecessario, setEmpregoNecessario] = useState('');
    const changeNecessario = (e) => {
        if (e.target.value) setEmpregoNecessario(e.target.value.toUpperCase())
    }  
    
    const [empregoDesejado, setEmpregoDesejado] = useState('');
    const changeDesejado = (e) => {
        if (e.target.value) setEmpregoDesejado(e.target.value.toUpperCase())
    }        
    
    const [empregoObservacoes, setEmpregoObservacoes] = useState('');
    const changeObservacoes = (e) => {
        if (e.target.value) setEmpregoObservacoes(e.target.value.toUpperCase())
    }        

    const [empregoStatus, setEmpregoStatus] = useState('');
    const changeStatus = (e) => {
        if (e.target.value) setEmpregoStatus(e.target.value.toUpperCase())
    }        

    const [empregoResponsavel, setEmpregoResponsavel] = useState('');
    const changeResponsavel = (e) => {
        if (e.target.value) setEmpregoResponsavel(e.target.value.toUpperCase())
    } 


    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            // customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12} >
                        <Field name="nome" type="text" component={renderTextField} label="Nome" normalize={upper} style={{maxWidth: '100%', width: 700 }} />
                    </Grid>
                    
                    <Grid item xs={isMobile?12:6} >
                        <Field name="cnpj" type="text" component={renderTextField} label="CNPJ" normalize={upper} style={{ width: 300 }} maxLength={11} {...cnpjMask()} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="phone" type="text" component={renderTextField} label="Telefone" normalize={upper} style={{ maxWidth: '100%', width: 300 }} {...phoneMask}/>
                    </Grid>                     

                    <Grid item xs={isMobile?12:6} >
                        <Field name="sigla" type="text" component={renderTextField} label="Nome Curto" normalize={upper} style={{ width: 300 }} />
                    </Grid>                    

                    <Grid item xs={isMobile?12:6}>
                        <Field name="latitudeMin" type="text" component={renderTextField} label="Latitude" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>                                        

                    <Grid item xs={isMobile?12:6}>
                        <Field name="longetudeMin" type="text" component={renderTextField} label="Longetude" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>   
{/* 
                    <Grid item xs={isMobile?12:6}>
                        <Field name="latitudeMax" type="text" component={renderTextField} label="Zona" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>                                    

                    <Grid item xs={isMobile?12:6}>
                        <Field name="longetudeMax" type="text" component={renderTextField} label="Sessão" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>    */}

                    <Grid item xs={12}>
                            <Field name="detail" type="textarea" component={renderTextAreaField} label="Observações" normalize={upper} style={{ maxWidth: '100%', width: 700 }} />
                        </Grid>                    

                    {/* <Grid item xs={12}>
                        <Field checked={dadosEndereco} name="dadosEndereco" type="text" component={renderSwitch} onChange={clickDadosEndereco} label="Endereço" style={{ maxWidth: '100%', width: 100}} />
                    </Grid>         */}

                    <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Endereço" value="1" />
                            <Tab label="Empregos" value="2" disabled={!entity.id}  />
                        </TabList>
                        </Box>
                        <TabPanel value="1">        
                            <Grid container spacing={3} wrap="wrap">
                                <Grid item xs={12}>
                                    <Field name="cep" onBlur={getDataByCEP} type="text" component={renderTextField} label="CEP" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                                </Grid>   

                                <Grid item xs={isMobile?12:6}>
                                    <Field name="address" type="text" component={renderTextField} label="Endereço" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                                </Grid>   

                                <Grid item xs={isMobile?12:6}>
                                    <Field name="number" type="text" component={renderTextField} label="Número" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                                </Grid>   

                                <Grid item xs={isMobile?12:6}>
                                    <Field name="neighborhood" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                                </Grid>                                               

                                <Grid item xs={isMobile?12:6}>
                                    <Field name="city" type="text" component={renderTextField} label="Cidade" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                                </Grid>   

                                <Grid item xs={isMobile?12:6}>
                                    <InputLabel>Estado</InputLabel>
                                    <Field id="state" name="state" component={renderSelectField} style={{ maxWidth: '100%', width: 300 }}>
                                        <option value={'AC'}>Acre</option>
                                        <option value={'AL'}>Alagoas</option>
                                        <option value={'AP'}>Amapá</option>
                                        <option value={'AM'}>Amazonas</option>
                                        <option value={'BA'}>Bahia</option>
                                        <option value={'CE'}>Ceará</option>
                                        <option value={'DF'}>Distrito Federal</option>
                                        <option value={'ES'}>Espírito Santo</option>
                                        <option value={'GO'}>Goiás</option>
                                        <option value={'MA'}>Maranhão</option>
                                        <option value={'MT'}>Mato Grosso</option>
                                        <option value={'MS'}>Mato Grosso do Sul</option>
                                        <option value={'MG'}>Minas Gerais</option>
                                        <option value={'PA'}>Pará</option>
                                        <option value={'PB'}>Paraíba</option>
                                        <option value={'PR'}>Paraná</option>
                                        <option value={'PE'}>Pernambuco</option>
                                        <option value={'PI'}>Piauí</option>
                                        <option value={'RJ'}>Rio de Janeiro</option>
                                        <option value={'RN'}>Rio Grande do Norte</option>
                                        <option value={'RS'}>Rio Grande do Sul</option>
                                        <option value={'RO'}>Rondônia</option>
                                        <option value={'RR'}>Roraima</option>
                                        <option value={'SC'}>Santa Catarina</option>
                                        <option value={'SP'}>São Paulo</option>
                                        <option value={'SE'}>Sergipe</option>
                                        <option value={'TO'}>Tocantins</option>
                                    </Field>
                                </Grid>

                                <Grid item xs={isMobile?12:6}>
                                    <Field name="complement" type="text" component={renderTextField} label="Complemento" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                                </Grid>

                            </Grid>

                        </TabPanel>
                        <TabPanel value="2"  >
                                    {showVagasEmpregos &&                         
                                        <Grid container spacing={3} wrap="wrap">

                                            <Card className='cardPersonalData'>
                                                <CardHeader title="Vagas de Empregos"/>
                                                <CardContent>
                                                    <Grid item  container xs={12} spacing={2} wrap='wrap' >
                                                        <VagasEmpregosList  style={{  maxWidth:'100%'}}/>
                                                    </Grid>

                                                    <Grid container spacing={2} wrap="wrap">
                                                        <Grid item xs={12}>
                                                            <Field 
                                                                name="empregoTitulo"
                                                                type="text"
                                                                component={renderTextField}
                                                                label="Nome da Vaga"
                                                                style={{ width: 700, maxWidth:'100%' }}
                                                                normalize={upper}
                                                                onChange={changeTitulo}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Field
                                                                name="empregoDescricao"
                                                                type="text"
                                                                component={renderTextAreaField}
                                                                label="Descrição"
                                                                style={{ width: 700 , maxWidth:'100%' }}
                                                                normalize={upper}
                                                                onChange={changeDescricao}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Field
                                                                name="empregoNecessario"
                                                                type="text"
                                                                component={renderTextAreaField}
                                                                label="Habilidades Necessárias"
                                                                style={{ width: 700 , maxWidth:'100%' }}
                                                                normalize={upper}
                                                                onChange={changeNecessario}
                                                            />
                                                        </Grid>       

                                                        <Grid item xs={12}>
                                                            <Field
                                                                name="empregoDesejada"
                                                                type="text"
                                                                component={renderTextAreaField}
                                                                label="Habilidades Desejadas"
                                                                style={{ width: 700 , maxWidth:'100%' }}
                                                                normalize={upper}
                                                                onChange={changeDesejado}
                                                            />
                                                        </Grid>  

                                                        <Grid item xs={12}>
                                                            <Field
                                                                name="empregoObservacoes"
                                                                type="text"
                                                                component={renderTextAreaField}
                                                                label="Observações"
                                                                style={{ width: 700 , maxWidth:'100%' }}
                                                                normalize={upper}
                                                                onChange={changeObservacoes}
                                                            />
                                                        </Grid>


                                                        <Grid item xs={isMobile?12:6}>
                                                            <Field
                                                                name="empregoStatus"
                                                                type="text"
                                                                component={renderSelectField}
                                                                label="Status"
                                                                style={{ width: 350 , maxWidth:'100%' }}
                                                                normalize={upper}
                                                                onChange={changeStatus}
                                                            >
                                                                <option value={'DISPONÍVEL'}>DISPONÍVEL</option>
                                                                <option value={'OCUPADA'}>OCUPADA</option>
                                                                <option value={'OCUPADA'}>CANCELADA</option>
                                                            </Field>                                                
                                                        </Grid>    

                                                        <Grid item xs={isMobile?12:6}>
                                                            <Field name="enpregoOwnerId" component={renderSelectField} label="Responsável" onChange={changeResponsavel} style={{ maxWidth: '100%', width: 350 }}>
                                                                {renderSelection(responsavelList)}
                                                            </Field>
                                                        </Grid>                                                                                        

                                                        <Grid item xs={12}> 
                                                            <Button 
                                                                style={{ width: 350, maxWidth:'100%', height:40 }} 
                                                                onClick={addVagaEmprego} 
                                                                color="secondary" 
                                                                variant="outlined"
                                                                endIcon={ <Icon icon='person_add_alt_1' size='24' color="blue"/>   }
                                                                >                                                    
                                                                Adicionar                                       
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        }
                        </TabPanel>
                    </TabContext>
                    </Box>                                   
                        <Grid item xs={12}>
                            <MyDivider/>                      
                        </Grid>
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    // validate,
    initialValues:{
        'empregoStatus':'DISPONÍVEL',
        
    }
})(Form);

export default Form;

