import React from 'react';
import logo from './assets/logo.webp'
import './styles/header.css'

import ScrollIntoView from 'react-scroll-into-view';

function Header  ()  {
    
    
    return ( <header id="header">
        
        <div id="logo-header">
            <img src={logo} alt="Logo" />
        </div>

        <nav id="menu-header">
            <ScrollIntoView selector="#descricao">
                <button className= "button_descricao"><a href='#noticias'> <p className="noticias-header">Biografia</p></a></button>
            </ScrollIntoView>

            <ScrollIntoView selector="#redessociais">
                <button className= "button_descricao"><a href='#redessociais'> <p className="noticias-header">Redes Sociais</p></a></button>
            </ScrollIntoView>

            <ScrollIntoView selector="#noticias">
                <button className= "button_descricao"><a href='#noticias'> <p className="noticias-header">Notícias</p></a></button>
            </ScrollIntoView>            

            <ScrollIntoView selector="#login">
                    <button className="login-header">
                        Login
                        {/* <p className="login-header">Login</p> */}
                    </button>
            </ScrollIntoView>     

            {/* <button className= "button_descricao"><a href='#login'> <p className="login-header">Login</p></a></button> */}
        </nav>    

    </header>
 );
};

export default Header;