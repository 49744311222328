import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import {GridList} from '../../../components/GridList/GridList';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: "auto",
        maxWidth: "100%",
        // padding: theme.spacing(4),
        width: '100%',
        marginLeft: '1%'
    }
  }));
  
export const RegistrosUsersList = (props) => {
    const classes = useStyles();
    const selectedUserId = useSelector(state => state.formRegistroIndividual.comentario);

    
    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid container justify="flex-start" spacing={12} >
                <Grid item lg={12} xs={12}>
                    <Card className='cardPersonalData'>
                    <CardContent>
                        <Grid container spacing={12} wrap="wrap">
                            <GridList 
                                modelName={"Grupo Político"} 
                                disableNewButtom={true}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={`/users`}
                                enableActions={false}
                                hiddenDeleteButton={true}
                                tableWidth={800}
                                // style={{marginLeft:100}}
                                tableHeight={400}
                                columns={[                                
                                // { name: 'id', title: '#' },
                                { name: 'cpf', title: 'CPF', width:150 },
                                { name: 'name', title: 'Nome' },
                                { name: 'status', title: 'Situação', width:100 },
                                { name: 'email', title: 'e-mail' }
                                // { name: '$users.name$', title: 'Nome', getCellValue: row => row.users ? row.users.name : null, width: 200 },
                                // { name: 'dataRegistro', title: 'Dt. Registro', width: 100 },
                                // { name: 'horaRegistro', title: 'hr. Registro', width: 100 },
                                // { name: 'comentario', title: 'Comentário', width: 200 },
                                
                                // { name: '$secretaria.nome$', title: 'Nome', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 200 },                                                                
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

