import { CardContent, GridList } from "@material-ui/core";
import React from "react";
import CardGrid from "./CardGrid";

const ContentList = ({  rows, 
                        columns, 
                        route, 
                        endpoint, 
                        cardsPerRow, 
                        onRemoveItem, 
                        hideAddButton, 
                        hideEditButton, 
                        hideDeleteButton,
                        getImage,
                        pkid}) => {

    return <GridList cols={cardsPerRow || 4}>
        {rows.length > 0 ?
            rows.map((row) => <CardGrid 
                                    key={row.id} 
                                    row={row} 
                                    columns={columns} 
                                    route={route} 
                                    endpoint={endpoint} 
                                    onRemoveItem={onRemoveItem} 
                                    hideAddButton={hideAddButton} 
                                    hideEditButton={hideEditButton} 
                                    hideDeleteButton={hideDeleteButton}
                                    getImage={getImage} 
                                    pkid={pkid}                                                                      
                                    />)
            :
            <CardContent style={{textAlign: 'center', width: '100%'}}>Nenhum registro encontrado</CardContent>
        }
    </GridList>
}

export default ContentList;