import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const RegistroAtividadeList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Secretaria excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Registro Geral" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            <GridList modelName={"Registro Geral"} 
                                editModelRoute={'/registroAtividades'}
                                newModelRoute={'/registroAtividades/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'registroAtividades'}
                                enableActions={true}
                                columns={[
                                { name: '$users.name$', title: 'Nome', getCellValue: row => row.users ? row.users.name : null, width: 200 },
                                { name: 'dataRequisicao', title: 'Data', width: 120 },
                                { name: '$secretaria.nome$', title: 'Local de Votação', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 200 },
                                { name: '$escalasAtividades.descricao$', title: 'Escala', getCellValue: row => row.escalasAtividades ? row.escalasAtividades.descricao : null, width: 200 },
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

