import React from 'react';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const EscalasAtividadesIndividualList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {        
        ToastsStore.success('Escala do usuário excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Grupo Político" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            <GridList modelName={"escalasAtividadesIndividual"} 
                                editModelRoute={'/escalasAtividadesIndividual'}
                                newModelRoute={'/escalasAtividadesIndividual/new'}
                                deleteCallback={deleteCallback}                                
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'/escalasAtividadesIndividual'}
                                disableNewButtom={true}
                                enableActions={true}
                                hiddenDeleteButton={true}
                                columns={[               
                                    { name: '$escalasAtividade.descricao$', title: 'Descrição', getCellValue: row => row.escalasAtividade ? row.escalasAtividade.descricao : null, width: 200 },                                    

        //                         { name: 'id', title: '#' },
        //                         { name: '$secretaria.nome$', title: 'Unidade de Lotação', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 300 },                                
        //                         { name: '$user.name$', title: 'Nome', getCellValue: row => row.user ? row.user.name : null, width: 100 },
        //                         { name: '$escalasAtividade.descricao$', title: 'Descrição', getCellValue: row => row.escalasAtividade ? row.escalasAtividade.descricao : null, width: 400 },
        //                         { name: 'descricao', title: 'Descrição' },

        // // {model: EscalasAtividades},

        //                         { name: '$secretaria.nome$', title: 'Unidade de Lotação', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 300 },
                             
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

