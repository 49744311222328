import React, {useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import uuid from "uuid";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { renderSelectField, renderDatePicker} from '../../../components/Fields/Fields';
import api from '../../../services/api';
import { isMobile } from 'react-device-detect';
import { ToastMessage } from '../../../layouts';
import { validate } from '../../../services';

let Form = (props) => {
    const { handleSubmit, valid, submitting } = props;

    const [loading, setLoading] = useState(false);

    const pdfGenerate = values => {
        setLoading(true);

        if (!values.startDate || !values.endDate) {
            ToastMessage.warning('Campos obrigatórios: Início e Fim');
            setLoading(false);
            return;
        }

        return api.post(`reports/pdf`, {
            startDate: values.startDate,
            endDate: values.endDate,
            tipo: values.tipo
        })
        .then(response => {

            const base64STR = response.data.content;

            downloadPDF('relatorio_medico_' + uuid.v4(), base64STR)
            
            ToastMessage.success('PDFgerado com sucesso');
        })
        .catch(error => {
            ToastMessage.warning('Problemas ao gerar PDF: ' + error);
        })
        .finally(() => setLoading(false));
    }
    
    const downloadPDF = (filename, pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `${filename}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <form onSubmit={handleSubmit(pdfGenerate)}>
            <Card className='header'>
                <CardHeader title="Relatórios" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3} wrap="wrap">
                        <Grid item xs={isMobile?12:6}>
                            <Field label="Tipo" name="tipo" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                                <option value={'F'}>Financeiro</option>
                                <option value={'M'}>Médico</option>
                                <option value={'I'}>Indicação</option>
                                <option value={'O'}>Outros</option>
                            </Field>
                        </Grid>
                        <Grid item xs={isMobile?12:6}>
                            <Field name="startDate" type="date" component={renderDatePicker} label="Início" style={{ maxWidth: '100%', width: 350 }} {...{ required: true }} />
                        </Grid>
                        <Grid item xs={isMobile?12:6}>
                            <Field name="endDate" type="date" component={renderDatePicker} label="fim" style={{ maxWidth: '100%', width: 350 }} {...{ required: true }} />
                        </Grid>
                    </Grid>
                </CardContent>

                <Divider />
            </Card>
            <Card>
                <CardActions style={{ margin: "1rem" }}>
                    <Grid container direction="row" justify="space-evenly" alignItems="center">
                        <Button type="submit" color="primary" variant="contained" disabled={loading}>
                            Gerar PDF
                        </Button>
                    </Grid>
                </CardActions>
            </Card>
        </form>
    );
};

Form = reduxForm({
    form: 'EventsForm',
    validate
})(Form);

export default Form;

