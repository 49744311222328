import React, { useState} from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { validate } from '../../../services';
import { Grid, CardHeader, Button } from '@material-ui/core';
import { renderTextField, renderTextAreaField, renderSelectField, renderDatePicker } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { phoneMask } from '../../../services/masks';
import apiCEP from '../../../services/apiCEP';
import { ToastMessage } from '../../../layouts';
import EstadoOptions from './EstadoOptions';
import JobSection from './JobSection';
import EducationSection from './EducationSection';

const formName = 'Currículo';
const modelName = 'resumes';
const label = 'Currículo';

let Form = (props) => {

    const [entity, setEntity] = useState({});
    const [jobsComponent, setJobsComponent] = useState([]);
    const [educationsComponent, setEducationsComponent] = useState([]);

    const upper = value => value && value.toUpperCase()

    const customUpdateFormProps = (entity) => {
        const {
            educations: _,
            events: _1,
            jobs: _2,
            languages: _3,
            links: _4,
            publications: _5,
            references: _6,
            skills: _7,
            titles: _8,
            images: _9,
            ...resume
        } = entity;
        const {jobs, educations} = entity;
        entity.resume = resume;
        let jobList = []
        let educationList = []
        jobs.forEach((j) => {
            jobList.push(<JobSection idx={jobList.length} key={`jobs-${jobList.length}`}/>)
        })
        educations.forEach((e) => {
            educationList.push(<EducationSection idx={educationList.length} key={`educations-${educationList.length}`}/>)
        })
        setJobsComponent(jobList)
        setEducationsComponent(educationList)
    }

    const afterLoadEntity = (entity) => {
        setEntity(entity);
    }

    const afterInitialState = () => {
        setEntity({});
    }

    const customBeforeSubmit = (values) => {
        const {id, educations, jobs, submit_type} = values;
        const {
            educations: _,
            events: _1,
            jobs: _2,
            languages: _3,
            links: _4,
            publications: _5,
            references: _6,
            skills: _7,
            titles: _8,
            images: _9,
            ...others
        } = values;

        return {
            id: id,
            resume: others.resume,
            educations: educations,
            jobs: jobs,
            submit_type: submit_type
        };
    }

    const getDataByCEP = (event) => {
        const numberPattern = /\d+/g;
        let cep = (event.target.value.match( numberPattern ) || []).join("");

        if (cep) {
            apiCEP.get(`/ws/${cep}/json`)
            .then(data => {
                const values = data.data;
                props.dispatch(change(formName, 'resume.address', values.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'resume.neighborhood', values.bairro.toUpperCase()));
                props.dispatch(change(formName, 'resume.complement', values.complemento.toUpperCase()));
                props.dispatch(change(formName, 'resume.state', values.uf.toUpperCase()));
                props.dispatch(change(formName, 'resume.city', values.localidade.toUpperCase()));
            })
            .catch(error => {
                console.error(error);
                ToastMessage.error('CEP não encontrado');
            })
        }
    }

    const addJobComponent = (event) => {
        event && event.preventDefault();
        let newJobs = [...jobsComponent];
        newJobs.push(<JobSection idx={newJobs.length} key={`jobs-${newJobs.length}`}/>)
        setJobsComponent(newJobs);
    }

    const addEducationComponent = (event) => {
        event && event.preventDefault();
        let newEducations = [...educationsComponent];
        newEducations.push(<EducationSection idx={newEducations.length} key={`educations-${newEducations.length}`}/>)
        setEducationsComponent(newEducations);
    }

    return (
        <BasicForm
            imageContext={'resumes'}
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            routeAfterSubmit={'/resumes/:id'}
            customAfterInitialState={afterInitialState}
            customBeforeSubmit={customBeforeSubmit}
            customUpdateFormProps={customUpdateFormProps}
            hiddenNewButton={true}
            maxImages={1}
            content={
                <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={12}>
                        <CardHeader title="Pessoal" titleTypographyProps={{ variant:'h3' }}/>
                    </Grid>

                    <Grid item xs={12} >
                        <Field name="resume.name" type="text" component={renderTextField} label="Nome" normalize={upper} style={{width: '100%' }} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={12} >
                        <Field name="resume.professionalSummary" type="text" component={renderTextAreaField} label="Resumo profissional" normalize={upper} style={{width: '100%'}} {... {required: true}} />
                    </Grid>

                    <Grid item xs={6} >
                        <Field name="resume.phone" type="text" component={renderTextField} label="Telefone" style={{maxWidth: '100%', width: 350 }} {...phoneMask} {...{required: true}} />
                    </Grid>

                    <Grid item xs={6} >
                        <Field name="resume.email" type="text" component={renderTextField} label="Email" style={{maxWidth: '100%', width: 350 }} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={12} >
                        <Field name="resume.hobbies" type="text" component={renderTextAreaField} label="Hobbies" normalize={upper} style={{width: '100%'}} />
                    </Grid>


                    <Grid item xs={12}>
                        <CardHeader title="Endereço" titleTypographyProps={{ variant:'h3' }}/>
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="resume.cep" onBlur={getDataByCEP} type="text" component={renderTextField} label="CEP" style={{width: '100%'}} {... {required: true}} />
                    </Grid>

                    <Grid item xs={6} >
                        <Field name="resume.neighborhood" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{width: '100%'}} />
                    </Grid>

                    <Grid item xs={10} >
                        <Field name="resume.address"  type="text" component={renderTextField} label="Rua" normalize={upper} style={{width: '100%'}} {... {required: true}} />
                    </Grid> 

                    <Grid item xs={2}>
                        <Field name="resume.number" type="text" component={renderTextField} label="Número" normalize={upper} style={{width: '100%'}} />
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="resume.complement" type="text" component={renderTextField} label="Complemento" normalize={upper} style={{width: '100%'}} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="resume.state" type="text" component={renderSelectField} label="Estado" normalize={upper} style={{width: 350}} {... {required: true}} >
                            <EstadoOptions />
                        </Field>
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="resume.city" type="text" component={renderTextField} label="Cidade" normalize={upper} style={{width: '100%'}} {... {required: true}} />
                    </Grid>

                    <Grid item xs={12}>
                        <CardHeader title="Escolaridade" titleTypographyProps={{ variant:'h3' }}/>
                    </Grid>

                    {educationsComponent}
                    <Grid item xs={12}>
                        <Button onClick={addEducationComponent} color="primary" variant="contained">Adicionar escolaridade</Button>
                    </Grid>

                    <Grid item xs={12}>
                        <CardHeader title="Histórico Profissional" titleTypographyProps={{ variant:'h3' }} />
                    </Grid>

                    {jobsComponent}

                    <Grid item xs={12}>
                        <Button onClick={addJobComponent} color="primary" variant="contained">Adicionar emprego</Button>
                    </Grid>
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
})(Form);

export default Form;

